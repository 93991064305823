import { useTranslation } from 'react-i18next';
import { CompletionStats } from '../../../../../../common/components/CompletionStats';
import { useGetDetailedUserCompletionsQuery } from '../../../../../../redux/api/dashboardApi';

interface Props {
	userId: string;
}

export const AllTimeCompletions: React.FC<Props> = ({ userId }: Props) => {
	const { t } = useTranslation('dashboard');
	const { data, isLoading } = useGetDetailedUserCompletionsQuery(userId, {
		skip: !userId,
	});

	return (
		<CompletionStats
			title={t('dashboard:detailed_user_view:learning_progress')}
			data={data}
			isLoading={isLoading}
		/>
	);
};
