import { StringParam, useQueryParam, withDefault } from 'use-query-params';

export const useActiveStory = () => {
	const [activeStory] = useQueryParam<string | null>(
		'storyId',
		withDefault(StringParam, null)
	);

	return { activeStory };
};
