import { format } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePageBreadcrumbs } from '../../common/hooks/usePageBreadcrumbs';
import { UserSwitcher } from '../../features/dashboard/components/user-details/common/UserSwitcher';
import { UserDetails } from '../../features/dashboard/components/user-details/UserDetails';
import { useGetDetailedUserActivityOverviewQuery } from '../../redux/api/dashboardApi';
import { BasePage } from '../common/BasePage';
import { ViewStoryButton } from '../../common/components/story-detail/buttons/ViewStoryButton';
import { SchemaJourneyResponseDto } from '@jam/api-sdk/api';
import { useGetJourneysQuery } from '../../redux/api/cmsApi';

const useManagerDashboardUserDetailsPageBreadcrumbs = (userId?: string) => {
	const { t } = useTranslation('dashboard');
	return usePageBreadcrumbs(
		[
			{
				title: t('activity_dashboard'),
				id: 'ManagerDashboardUserDetails',
				link: `/dashboard/user/${userId ?? ''}`,
			},
		],
		!userId
	);
};

const usePageData = () => {
	const { i18n } = useTranslation();
	const locale = i18n.language === 'en' ? enGB : de;
	const { userId } = useParams<{ userId: string }>();
	const { data, isFetching } = useGetDetailedUserActivityOverviewQuery(
		userId as string,
		{
			skip: !userId,
		}
	);

	const { data: journeys } = useGetJourneysQuery();

	return { locale, userId, data, isFetching, journeys };
};

export const ManagerDashboardUserDetails = () => {
	const { t } = useTranslation(['dashboard', 'common']);
	const { userId, data, isFetching, locale, journeys } = usePageData();
	const steps = useManagerDashboardUserDetailsPageBreadcrumbs(userId);

	const renderStoryCardButton = (storyId: string) => {
		const journey: SchemaJourneyResponseDto | undefined = journeys?.find((j) =>
			j.stories?.some((story) => story.id === storyId)
		);

		return <ViewStoryButton storyId={storyId} journeyId={journey?.id} />;
	};

	if (isFetching || !data) {
		return null;
	}

	return (
		<BasePage
			title={data.name}
			subtitles={[
				data.registeredSince
					? t('common:joined_date', {
							date: format(new Date(data.registeredSince), 'MMM d, yyyy', {
								locale,
							}),
						})
					: '',
				data.lastSessionDate
					? t('common:last_session_date', {
							date: format(
								new Date(data.lastSessionDate ?? ''),
								'MMM d, yyyy',
								{
									locale,
								}
							),
						})
					: '',
			]}
			titleActions={<UserSwitcher />}
			breadcrumbsProps={{
				items: steps,
			}}
		>
			<UserDetails
				userId={userId}
				userName={data.name}
				renderCurrentAssignmentStoryCardButton={renderStoryCardButton}
				renderCompletedAssignmentStoryCardButton={renderStoryCardButton}
			/>
		</BasePage>
	);
};
