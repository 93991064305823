import { Box, Divider, Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import { SchemaSessionResponseDto } from '@jam/api-sdk/api';
import { Info } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	title: string;
	hint: string;
	value: string;
};

const KpiStat: React.FC<Props> = ({ title, value, hint }: Props) => {
	return (
		<Box>
			<Flex alignItems="center">
				<Text fontSize="10px" color="#757575">
					{title}
				</Text>
				<Tooltip
					label={hint}
					placement="top"
					hasArrow
					p={4}
					borderRadius={'16px'}
					bg="gray.900"
					color="white"
					maxW={'200px'}
					fontSize="xs"
				>
					<Box as="span" ml={'2px'} cursor="help">
						<Info size={10} color="#757575" />
					</Box>
				</Tooltip>
			</Flex>
			<Text fontSize="12px" fontWeight={'500'}>
				{value}
			</Text>
		</Box>
	);
};

export type KpiStatsProps = {
	kpis: SchemaSessionResponseDto['kpis'];
};

export const KpiStats: React.FC<KpiStatsProps> = ({ kpis }: KpiStatsProps) => {
	const { t } = useTranslation(['call', 'common']);

	return (
		<Flex
			direction={'column'}
			bg={'#F5F5F5'}
			p="4"
			borderTop={'1px solid #D9D9D9'}
			borderBottomRadius={'24px'}
		>
			<Text fontSize="sm" fontWeight="medium" mb={2}>
				{t('common:conversation_stats')}
			</Text>
			<Box
				borderRadius={'8px'}
				bg={'white'}
				px="2"
				py={2}
				border="1px solid #D9D9D9"
			>
				<Stack
					direction="row"
					spacing={2}
					divider={<Divider orientation="vertical" h="40px" />}
					width="100%"
					justifyContent="space-around"
				>
					<KpiStat
						title={t('listen_ratio')}
						hint={t('listen_ratio_hint')}
						value={`${Math.round((kpis.listenRatio ?? 0) * 100)}%`}
					/>
					<KpiStat
						title={t('talk_speed')}
						hint={t('talk_speed_hint')}
						value={`${((kpis.talkSpeed ?? 0) * 60).toFixed(0)} words/min`}
					/>
					<KpiStat
						title={t('longest_monolog')}
						hint={t('longest_monolog_hint')}
						value={`${kpis.longestMonologue ?? 0} seconds`}
					/>
				</Stack>
			</Box>
		</Flex>
	);
};
