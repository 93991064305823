import { SchemaWeeklyActivityAcrossAllUsersResponseDto } from '@jam/api-sdk/api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BarProps } from 'recharts';
import { ActiveShape } from 'recharts/types/util/types';
import { WeeklyUsersChartBarShape } from '../common/bar-shapes/WeeklyUsersChartBarShape';
import DashboardChart from '../common/charts/DashboardChart';

interface WeeklyUsersChartProps {
	userActivity: SchemaWeeklyActivityAcrossAllUsersResponseDto['activeUsers'];
}

export const WeeklyUsersChart: React.FC<WeeklyUsersChartProps> = ({
	userActivity,
}) => {
	const { t } = useTranslation(['dashboard', 'common']);

	const keys = Object.keys(userActivity).sort();
	const regex = /W(\d+)/;

	const data = keys.map((key) => ({
		name: t('team_activity_section.week_number', {
			weekNumber: key.match(regex)?.[1],
		}),
		active: userActivity[key].active ?? 0,
		inactive: userActivity[key].inactive ?? 0,
	}));

	const bars = [
		{
			dataKey: 'active',
			name: t('common:active') ?? 'Active',
			fill: '#027864',
			shape: WeeklyUsersChartBarShape as
				| ActiveShape<BarProps, SVGPathElement>
				| undefined,
		},
		{
			dataKey: 'inactive',
			name: t('common:inactive') ?? 'Inactive',
			fill: '#FFBA18',
			shape: WeeklyUsersChartBarShape as
				| ActiveShape<BarProps, SVGPathElement>
				| undefined,
		},
	];

	const legendItems = [
		{ color: '#027864', label: t('common:active') ?? 'Active' },
		{ color: '#FFBA18', label: t('common:inactive') ?? 'Inactive' },
	];

	return (
		<DashboardChart
			title={t('team_activity_section.active_users')}
			hint={t('team_activity_section.active_users_hint')}
			data={data}
			xAxisKey="name"
			bars={bars}
			legendItems={legendItems}
		/>
	);
};
