/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Box,
	Flex,
	Text,
	Tooltip as ChakraTooltip,
	useDisclosure,
} from '@chakra-ui/react';
import Select from 'react-select';
import {
	CartesianGrid,
	YAxis,
	XAxis,
	Line,
	LineChart,
	ResponsiveContainer,
	DotProps,
} from 'recharts';
import { useRef } from 'react';
import { FilteringToolbar } from '../../common/components/filtering/FilteringToolbar';
import { Circle, Info } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useOutsideAlerter } from '../../common/hooks/useOutsideClickAlerter';
import {
	SchemaStoryResponseDto,
	SchemaUserPerformanceByRoundResponseDto,
	SchemaUserResponseDto,
} from '@jam/api-sdk/api';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const COLORS = [
	'#4241E4',
	'#2B9A5E',
	'#F59E0B',
	'#1D4ED8',
	'#15803D',
	'#DC2626',
	'#7C3AED',
	'#DB2777',
	'#0891B2',
	'#CA8A04',
];

interface CustomDotProps extends DotProps {
	value?: number;
	dataKey?: string;
	payload?: SchemaUserPerformanceByRoundResponseDto;
}

export const CustomDot = (props: CustomDotProps) => {
	const { t } = useTranslation('performance');
	const { cx, cy, stroke, value, payload, dataKey } = props;
	const navigate = useNavigate();
	const wrapperRef = useRef(null);
	const userId = dataKey?.split('.')[0];
	const { isOpen, onOpen, onClose } = useDisclosure();
	useOutsideAlerter(wrapperRef, onClose);
	if (!cx || !cy || !userId) return <></>;

	const sessionInfo = payload?.[userId];
	const hasSessionInfo = !!sessionInfo?.sessionId;

	return (
		<ChakraTooltip
			pointerEvents={'all'}
			cursor={'pointer'}
			bg="white"
			color="black"
			p="2"
			borderRadius="8px"
			border="1px solid #D9D9D9"
			fontWeight={'400'}
			placement="top-start"
			ref={wrapperRef}
			isOpen={isOpen}
			label={
				<Flex bg="transparent" direction="column">
					<Text>
						{value === -1
							? t('performance_chart_graph.no_session_yet')
							: t('performance_chart_graph.score', {
									score: value,
								})}
					</Text>
					{hasSessionInfo && (
						<>
							{sessionInfo.date && (
								<Text>
									{t('performance_chart_graph.date', {
										date: format(sessionInfo.date, 'MMM dd, yyyy'),
									})}
								</Text>
							)}
							{typeof sessionInfo.tasksCompleted === 'number' &&
								typeof sessionInfo.tasksTotal === 'number' && (
									<Text>
										{t('performance_chart_graph.tasks_solved', {
											completed: sessionInfo.tasksCompleted,
											total: sessionInfo.tasksTotal,
										})}
									</Text>
								)}
							<Text
								fontWeight={'500'}
								onClick={() => {
									navigate(
										`/dashboard/user/${userId}/session/${sessionInfo.sessionId}?activeCallTabIndex=2`
									);
								}}
								mt={2}
								color="#4241E4"
								cursor={'pointer'}
							>
								{t('performance_chart_graph.view_feedback')}
							</Text>
						</>
					)}
				</Flex>
			}
		>
			{value === 100 ? (
				<svg
					cursor={'pointer'}
					onClick={onOpen}
					x={cx - 6}
					y={cy - 6}
					width={12}
					height={12}
					fill="#2B9A5E"
					viewBox="0 0 24 24"
					style={{ stroke: 'white', strokeWidth: 2 }}
				>
					<path d="M12 0a12 12 0 100 24 12 12 0 000-24zm6.7 8.7l-8 8c-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-4-4c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l3.3 3.3 7.3-7.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z" />
				</svg>
			) : (
				<circle
					cursor={'pointer'}
					onClick={onOpen}
					cx={cx}
					cy={cy}
					r={5}
					style={{ stroke: 'white', strokeWidth: 2 }}
					fill={value === -1 ? 'gray' : stroke}
				/>
			)}
		</ChakraTooltip>
	);
};

type PerformanceChartProps = {
	data: any[];
	alwaysShownUserId?: string;
	shownUsers: string[];
	allEmpty?: boolean;
	teamMembers: SchemaUserResponseDto[];
	selectedMission?: SchemaStoryResponseDto;
	missions: SchemaStoryResponseDto[];
	onMissionChange: (value: string) => void;
	onUsersChange: (users: string[]) => void;
};

export const PerformanceChart = ({
	data,
	alwaysShownUserId,
	shownUsers,
	teamMembers,
	selectedMission,
	missions,
	allEmpty,
	onMissionChange,
	onUsersChange,
}: PerformanceChartProps) => {
	const { t } = useTranslation('performance');

	const userListOptions = teamMembers
		?.filter((user) => user.id !== alwaysShownUserId)
		.map((user) => ({
			label: `${user.firstName} ${user.lastName}`,
			value: user.id,
		}));

	return (
		<Flex direction={{ base: 'column' }} gap="5">
			<Flex gap="5">
				<Box w="350px">
					<Select
						isMulti
						placeholder={t('performance_chart_graph.select_users')}
						options={userListOptions}
						value={userListOptions.filter((user) =>
							shownUsers.includes(user.value)
						)}
						onChange={(value) => onUsersChange(value?.map((v) => v.value))}
					/>
				</Box>
				<FilteringToolbar
					filters={[
						{
							id: 'missionId',
							type: 'select',
							options: [
								{
									label: t('performance_chart_graph.all_missions'),
									value: '',
								},
								...missions.map((mission) => ({
									label: mission.title,
									value: mission.id,
								})),
							],
							onChange: (value) => onMissionChange(value),
							value: selectedMission?.id ?? '',
						},
					]}
				/>
			</Flex>
			<Box
				h={'450px'}
				p="5"
				borderRadius="16px"
				border="1px solid #D9D9D9"
				position="relative"
			>
				{allEmpty && (
					<Flex
						position="absolute"
						top="0"
						left="0"
						right="0"
						bottom="0"
						bg="rgba(0, 0, 0, 0.05)"
						alignItems="center"
						justifyContent="center"
						borderRadius="16px"
					>
						<Text color="gray.600">
							{t('performance_chart_graph.no_missions_played')}
						</Text>
					</Flex>
				)}
				<Text mb="3" fontSize={'14px'} fontWeight={'500'} color="#0B1215">
					{selectedMission
						? selectedMission?.title
						: t('performance_chart_graph.all_missions')}
				</Text>
				<Flex justify={'space-between'} align={'center'}>
					<Flex gap="1" direction="column-reverse" alignItems="center">
						<Text
							textAlign={'right'}
							fontSize={'12px'}
							color="#757575"
							style={{
								writingMode: 'vertical-rl',
								transform: 'rotate(180deg)',
							}}
						>
							{t('performance_chart_graph.total_score')}
						</Text>
						<ChakraTooltip
							label={t('performance_chart_graph.total_score_helper')}
						>
							<Info color="#757575" size={16} />
						</ChakraTooltip>
					</Flex>
					<ResponsiveContainer width={'100%'} height={350}>
						<LineChart
							data={data}
							margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
						>
							<CartesianGrid horizontal={false} />
							<XAxis
								dataKey="round"
								tickLine={false}
								axisLine={false}
								width={0}
							/>
							<YAxis
								axisLine={false}
								tickLine={false}
								domain={[0, 105]}
								ticks={[0, 20, 40, 60, 80, 100]}
							/>
							{shownUsers.map((uid, index) => (
								<Line
									key={uid}
									type={'linear'}
									dataKey={`${uid}.score`}
									dot={<CustomDot />}
									isAnimationActive={false}
									connectNulls={false}
									stroke={COLORS[index % COLORS.length]}
								/>
							))}
						</LineChart>
					</ResponsiveContainer>
					<Flex gap="1" direction="column" w="170px">
						{shownUsers.map((uid, index) => {
							const user = teamMembers?.find((u) => u.id === uid);
							return (
								<Flex key={uid} gap="1" alignItems="center">
									<Circle
										weight="fill"
										size="12px"
										color={COLORS[index % COLORS.length]}
									/>
									<Text fontSize="12px" color="#757575">
										{`${user?.firstName} ${user?.lastName}`}
									</Text>
								</Flex>
							);
						})}
					</Flex>
				</Flex>
				<Flex
					mt="2"
					gap="1"
					justifyContent="flex-end"
					fontSize={'12px'}
					color="#757575"
				>
					<Text>{t('performance_chart_graph.rounds_played')}</Text>
					<ChakraTooltip
						label={t('performance_chart_graph.rounds_played_helper')}
					>
						<Info color="#757575" size={16} />
					</ChakraTooltip>
				</Flex>
			</Box>
		</Flex>
	);
};
