import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	CircularProgress,
	CircularProgressLabel,
	Flex,
	Stack,
	Text,
} from '@chakra-ui/react';
import {
	PathsApiV1SessionsGetParametersQueryType,
	SchemaSessionResponseDto,
	SessionResponseDTOAnalysisItemsSolved,
	SessionResponseDTOAnalysisState,
} from '@jam/api-sdk/api';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Trans, useTranslation } from 'react-i18next';
import { Chip } from 'front-library';
import { XpIcon } from '../../../common/components/Icons';
import { useIsOnboarding } from '../../../common/hooks/useIsOnboarding';
import { OnboardingFeature } from '../../../common/types/Onboarding';
import { useGetItemUserFeedbackBySessionIdQuery } from '../../../redux/api/userFeedbackApi';
import { FeedbackNotFinished } from './FeedbackNotFinished';
import { SessionUserFeedback } from './SessionUserFeedback';

type SessionFeedbackProps = {
	session: SchemaSessionResponseDto | null | undefined;
};

const SolvedStatusToColorMap: Record<
	SessionResponseDTOAnalysisItemsSolved,
	{
		bg: string;
		color: string;
	}
> = {
	[SessionResponseDTOAnalysisItemsSolved.solved]: {
		bg: '#EAFDEE',
		color: '#147129',
	},
	[SessionResponseDTOAnalysisItemsSolved.partlySolved]: {
		bg: 'yellow.100',
		color: 'yellow.500',
	},
	[SessionResponseDTOAnalysisItemsSolved.notSolved]: {
		bg: 'rgba(255, 0, 0, 0.1)',
		color: 'rgba(255, 0, 0, 0.6)',
	},
	[SessionResponseDTOAnalysisItemsSolved.undetected]: {
		bg: '#F5F5F5',
		color: '#757575',
	},
};

export const SessionFeedback = ({ session }: SessionFeedbackProps) => {
	const { t } = useTranslation('call');
	const isOnboarding = useIsOnboarding(OnboardingFeature.CALL_PAGE);

	const { data: userFeedbacksPerItem } = useGetItemUserFeedbackBySessionIdQuery(
		session?.id ?? '',
		{
			skip: !session,
		}
	);

	if (!session) {
		return null;
	}

	if (
		!session.analysis ||
		session.analysis.state !== SessionResponseDTOAnalysisState.DONE
	) {
		// session is stuck in pending for more than 30 minutes give up
		if (new Date(session.createdAt).getTime() + 1000 * 60 * 30 < Date.now()) {
			return <FeedbackNotFinished status="STALLED" />;
		}

		return <FeedbackNotFinished status="PENDING" />;
	}

	const completedItems =
		session?.analysis?.items?.filter(
			(item) => item.solved === SessionResponseDTOAnalysisItemsSolved.solved
		)?.length || 0;

	const allItems = session?.analysis?.items?.length || 0;

	let text = 'solved_some_tasks';
	let helperText = 'solved_some_tasks_helper';
	if (completedItems === allItems) {
		text = 'solved_all_tasks';
		helperText = 'solved_all_tasks_helper';
	} else if (completedItems === 0) {
		text = 'solved_no_tasks';
		helperText = 'solved_no_tasks_helper';
	}

	const items = session.analysis.items;

	const notSolvedItemsIndexes = items
		.map((item, index) =>
			item.solved !== SessionResponseDTOAnalysisItemsSolved.solved ? index : -1
		)
		.filter((x) => x >= 0);

	// open all items in onboarding
	const openedIndexes = isOnboarding
		? Array.from(Array(items.length).keys())
		: notSolvedItemsIndexes;

	const shouldShowSummary =
		allItems > 0 &&
		session.type === PathsApiV1SessionsGetParametersQueryType.ROLE_PLAY;

	return (
		<Flex direction={'column'} gap="4">
			{shouldShowSummary && (
				<Flex
					border={'1px'}
					borderColor={'#D9D9D9'}
					bg={'#FAFAFA'}
					px="4"
					py="10px"
					gap="6"
					borderRadius={'24px'}
					alignItems={'center'}
				>
					<CircularProgress
						value={Math.round(session.analysis.score)}
						color="#4241E4"
						size={'70px'}
						trackColor="#DFDFFF"
					>
						<CircularProgressLabel fontSize={'16px'}>
							<Text fontSize={'16px'}>
								{Math.round(session.analysis.score)}%
							</Text>
						</CircularProgressLabel>
					</CircularProgress>
					<Flex gap={1} direction={'column'}>
						<Box fontWeight={'500'}>
							<Trans
								i18nKey={`call:${text}`}
								values={{
									completedItems,
									allItems,
									xp: session.completed ? session.storyXp : session.sessionXp,
								}}
							>
								Good effort! You solved
								<Text display={'inline'} color="#4241E4">
									{`${completedItems}/${allItems}`} items
								</Text>
								and earned 20 <XpIcon mb="2px" />. Review your feedback and try
								again.
							</Trans>
						</Box>
						<Text fontSize={'14px'} color={'#757575'}>
							{t(`call:${helperText}`)}
						</Text>
					</Flex>
				</Flex>
			)}
			<Accordion allowMultiple defaultIndex={openedIndexes}>
				{items.map((item) => {
					const userFeedback = userFeedbacksPerItem
						? userFeedbacksPerItem?.data.find(
								(x) => x.storyItemReferenceId === item.id
							) ?? null
						: undefined;

					return (
						<AccordionItem mb="5" key={item.id} border={0}>
							{({ isExpanded }) => (
								<Box
									borderRadius={'24px'}
									border={'1px'}
									borderColor={'#D9D9D9'}
									px="4"
									py="6"
								>
									<AccordionButton
										_hover={{ background: 'transparent' }}
										w="100%"
									>
										<Flex w="100%" alignItems="center">
											{isExpanded ? (
												<CaretUp weight="bold" size={20} />
											) : (
												<CaretDown weight="bold" size={20} />
											)}
											<Text
												fontWeight={'500'}
												w="fit-content"
												fontSize={'16px'}
												color="black"
												ml={4}
											>
												{item.name}
											</Text>
											<Chip
												ml="auto"
												bg={
													SolvedStatusToColorMap[
														item.solved ??
															SessionResponseDTOAnalysisItemsSolved.undetected
													].bg
												}
												color={
													SolvedStatusToColorMap[
														item.solved ??
															SessionResponseDTOAnalysisItemsSolved.undetected
													].color
												}
												w="fit-content"
												border={0}
												py="1"
												px={2}
												fontSize={'12px'}
												fontWeight={'500'}
												text={t(
													item.solved ??
														SessionResponseDTOAnalysisItemsSolved.undetected
												)}
											/>
										</Flex>
									</AccordionButton>
									<AccordionPanel pt={6}>
										<Stack direction={'row'} spacing={4}>
											<Flex w="50%" direction={'column'} gap="2">
												<Text fontSize="16px" color="black" fontWeight="500">
													{t('task_feedback')}
												</Text>
												<Text color="black" fontSize="14px" fontWeight="400">
													{item.explanation}
												</Text>
											</Flex>
											<Flex w="50%" direction={'column'} gap="2">
												<Text fontSize="16px" color="black" fontWeight="500">
													{t('nextTime')}
												</Text>
												<Text color="black" fontSize="14px" fontWeight="400">
													{item.suggestion}
												</Text>
											</Flex>
										</Stack>
										<Box mt={8}>
											<SessionUserFeedback
												sessionId={session.id}
												item={item}
												userFeedback={userFeedback}
											/>
										</Box>
									</AccordionPanel>
								</Box>
							)}
						</AccordionItem>
					);
				})}
			</Accordion>
		</Flex>
	);
};
