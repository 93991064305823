import { useToast } from '@chakra-ui/react';
import {
	JourneyResponseDTOStoriesSituationConversation_type,
	SchemaStoryResponseDto,
} from '@jam/api-sdk/api';
import Vapi from '@vapi-ai/web';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	useCreateSessionMutation,
	useDeleteSessionMutation,
} from '../../../redux/api/sessionApi';
import { useAppSelector } from '../../../redux/hooks';
import {
	selectCurrentCallStatus,
	setBotIsSpeaking,
	setCurrentCallStatus,
} from '../../../redux/slice';
import soundPhoneCallEnd from '../../call/assets/phone_call_end.mp3';
import soundPhoneCallPickUp from '../../call/assets/phone_call_pick_up.mp3';
import soundPhoneCall from '../../call/assets/phone_call_start.mp3';
import { CallStatus } from '../components/CallStatus';
import { SimulateSalesAgentUrlParameter } from '../types/SimulateSalesAgent';
import {
	ClientEventMessage,
	ClientEventMessageToolCalls,
	Metadata,
	ToolCallEndConversationFunctionNameSchema,
	VapiEventMessageType,
} from '../types/VapiClientEventMessage';

const vapiToken = import.meta.env.VITE_VAPI_PUBLIC_KEY as string;

export const useVapi = ({ story }: { story: SchemaStoryResponseDto }) => {
	const [vapi] = useState(new Vapi(vapiToken));
	const dispatch = useDispatch();
	const [sessionId, setSessionId] = useState<string | null>(null);
	const [deleteSession] = useDeleteSessionMutation();
	const [createCall] = useCreateSessionMutation();
	const [error, setError] = useState<string | null>(null);
	const [startTime, setStartTime] = useState<number | null>(null);
	const [callDurationSec, setCallDurationSec] = useState<number | null>(null);
	const callStatus = useAppSelector(selectCurrentCallStatus);
	const urlParams = new URLSearchParams(window.location.search);
	const simulateSalesAgent =
		urlParams.get(SimulateSalesAgentUrlParameter) === 'true';
	const toast = useToast();
	const soundStartCall = new Audio(soundPhoneCall);
	const soundEndCall = new Audio(soundPhoneCallEnd);
	const soundPickUp = new Audio(soundPhoneCallPickUp);

	useEffect(() => {
		if (callStatus !== CallStatus.finished) return;

		setCallDurationSec(
			startTime ? Math.floor((Date.now() - startTime) / 1000) : null
		);
		setStartTime(null);
	}, [callStatus, startTime]);

	const start = useCallback(async () => {
		try {
			if (
				story.situation.conversation_type ===
				JourneyResponseDTOStoriesSituationConversation_type.call
			) {
				void soundStartCall.play();
			}

			dispatch(setCurrentCallStatus(CallStatus.loading));

			const payload = await createCall({
				storyId: story.id,
			}).unwrap();

			const assistantReferenceId: string = payload.call.assistantReferenceId;

			await vapi.start(assistantReferenceId, {
				metadata: {
					simulateSalesAgent,
				} as Metadata,
			});

			setSessionId(payload.id);
		} catch (err) {
			dispatch(setCurrentCallStatus(CallStatus.finished));
			setError((err as Error).message);
		}
	}, [story]);

	const cleanup = async (): Promise<void> => {
		if (
			!sessionId ||
			callStatus === CallStatus.started ||
			callStatus === CallStatus.loading
		) {
			return;
		}
		await deleteSession(sessionId);
		setSessionId(null);
	};

	const stop = () => {
		vapi.stop();
		dispatch(setCurrentCallStatus(CallStatus.finished));

		setError(null);
		dispatch(setBotIsSpeaking(false));
	};

	const checkMicrophoneStatus = () => {
		if (vapi.isMuted()) {
			toast({
				title: 'Microphone is muted',
				status: 'warning',
				duration: 5000,
				isClosable: true,
			});
		}
	};
	const handleToolCall = (clientEventMessage: ClientEventMessageToolCalls) => {
		const functionName = clientEventMessage.toolCalls[0]?.function.name;
		if (
			ToolCallEndConversationFunctionNameSchema.safeParse(functionName).success
		) {
			stop();
		}
	};
	// hook into Vapi events
	useEffect(() => {
		dispatch(setCurrentCallStatus(CallStatus.notStarted));

		vapi.on('call-start', () => {
			checkMicrophoneStatus();
			setStartTime(Date.now());
			setCallDurationSec(null);
			dispatch(setCurrentCallStatus(CallStatus.started));
			dispatch(setBotIsSpeaking(false));
			setError(null);

			if (
				story.situation.conversation_type ===
				JourneyResponseDTOStoriesSituationConversation_type.call
			) {
				void soundPickUp.play();
			}
		});

		vapi.on('call-end', () => {
			if (
				story.situation.conversation_type ===
				JourneyResponseDTOStoriesSituationConversation_type.call
			) {
				void soundEndCall.play();
			}
			dispatch(setBotIsSpeaking(false));
			dispatch(setCurrentCallStatus(CallStatus.finished));
		});

		vapi.on('speech-start', () => {
			dispatch(setBotIsSpeaking(true));
		});

		vapi.on('speech-end', () => {
			dispatch(setBotIsSpeaking(false));
		});

		vapi.on('message', (clientEventMessage: ClientEventMessage) => {
			if (clientEventMessage?.type === VapiEventMessageType.TOOL_CALLS) {
				handleToolCall(clientEventMessage as ClientEventMessageToolCalls);
			}
		});

		vapi.on('error', (err: Error) => {
			setError(err.message);
			dispatch(setCurrentCallStatus(CallStatus.finished));
			dispatch(setBotIsSpeaking(false));
		});

		// we only want this to fire on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return () => {
			dispatch(setCurrentCallStatus(null));
		};
	}, [vapi]);

	return {
		start,
		stop,
		deleteSession: cleanup,
		sessionId,
		error,
		callDurationSec,
	};
};
