import { useContext } from 'react';
import {
	NavigationBreadcrumbsContext,
	NavigationBreadcrumbsContextType,
} from './NavigationBreadcrumbsProvider';

export const useNavigationBreadcrumbs =
	(): NavigationBreadcrumbsContextType => {
		const context = useContext(NavigationBreadcrumbsContext);

		if (context === undefined) {
			throw new Error(
				'useNavigationBreadcrumbs must be used within a NavigationBreadcrumbsProvider'
			);
		}

		return context;
	};
