import { Box, Button, Skeleton, useDisclosure } from '@chakra-ui/react';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetDetailedUserActivityOverviewQuery } from '../../../../../redux/api/dashboardApi';
import { UserSelectModal } from './UserSelectModal';

const LoadingState = () => {
	return <Skeleton role="placeholder" mt="10" borderRadius={'24px'} h="50px" />;
};

export const UserSwitcher = () => {
	const { t } = useTranslation('common');
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { userId } = useParams();
	const { data, isFetching } = useGetDetailedUserActivityOverviewQuery(
		userId as string,
		{
			skip: !userId,
		}
	);

	if (isFetching || !data || !userId) return <LoadingState />;

	return (
		<Box>
			<Button
				_hover={{
					bg: 'brand.500',
				}}
				onClick={onOpen}
				leftIcon={<MagnifyingGlass size="20" />}
			>
				{t('change_user')}
			</Button>
			<UserSelectModal isOpen={isOpen} onClose={onClose} />
		</Box>
	);
};
