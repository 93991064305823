import {
	BreadCrumbProps,
	BreadCrumbs,
} from '../../core/breadcrumbs/BreadCrumbs';
import { useAppHistory } from '../../../../providers/history/useAppHistory';
import { useGoBack } from '../../../hooks/useGoBack';

export type NavigationBreadCrumbsProps = {
	showDefaultBack?: boolean;
} & BreadCrumbProps;

export const NavigationBreadCrumbs = (props: NavigationBreadCrumbsProps) => {
	const { history } = useAppHistory();
	const onBack = useGoBack();

	const uniquePreviouslyVisitedAppRoutes = Array.from(
		new Set(history.map((loc) => loc.pathname))
	);

	// if location is default then it means we are on the first page
	const hasHistory = uniquePreviouslyVisitedAppRoutes.length > 1;

	return (
		<BreadCrumbs
			{...(props ?? {})}
			showItems={props.items.length > 1}
			onBackClick={props.showDefaultBack || hasHistory ? onBack : undefined}
		/>
	);
};
