import {
	SchemaJourneyResponseDto,
	SchemaStoryResponseDto,
} from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'cms-api',
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		/**
		 * @see file://./../../../../api/src/cms/exports/controllers/story.controller.ts
		 */
		getStory: builder.query<SchemaStoryResponseDto, string>({
			query: (id: string) => `stories/${id}`,
		}),
		/**
		 * @see file://./../../../../api/src/cms/exports/controllers/journey.controller.ts
		 */
		getJourneys: builder.query<SchemaJourneyResponseDto[], void>({
			query: () => `journeys`,
		}),
		/**
		 * @see file://./../../../../api/src/cms/exports/controllers/journey.controller.ts
		 */
		getJourney: builder.query<SchemaJourneyResponseDto, string>({
			query: (journeyId: string) => `journeys/${journeyId}`,
		}),
		/**
		 * @see file://./../../../../api/src/cms/exports/controllers/story.controller.ts
		 */
		getStories: builder.query<SchemaStoryResponseDto[], void>({
			query: () => 'stories',
		}),
	}),
});

export const {
	useGetJourneysQuery,
	useGetJourneyQuery,
	useGetStoryQuery,
	useGetStoriesQuery,
} = api;
export default api;
