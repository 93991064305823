import React, { createContext, ReactNode, useCallback, useState } from 'react';

export interface BreadCrumbItem {
	id: string;
	link: string;
	title: string;
}

export interface NavigationBreadcrumbsContextType {
	steps: BreadCrumbItem[];
	setOriginStep: (originStep: BreadCrumbItem) => void;
	addStep: (step: BreadCrumbItem) => void;
	removeStep: (stepLink: string) => void;
}

export const NavigationBreadcrumbsContext = createContext<
	NavigationBreadcrumbsContextType | undefined
>(undefined);

export const NavigationBreadcrumbsProvider: React.FC<{
	children: ReactNode;
}> = ({ children }) => {
	const [steps, setSteps] = useState<BreadCrumbItem[]>([]);
	const [originStep, setOriginStep] = useState<BreadCrumbItem | null>(null);

	const getOriginStep = useCallback((): BreadCrumbItem => {
		return (
			originStep ?? {
				id: 'home',
				link: '/home',
				title: 'Home',
			}
		);
	}, [originStep]);

	const addStep = useCallback(
		(step: BreadCrumbItem): void => {
			// if exists, replace
			const index = steps
				.concat(getOriginStep() ?? [])
				.findIndex((s) => s.id === step.id);

			if (index !== -1) {
				const newSteps = [...steps];
				newSteps[index] = step;
				setSteps(newSteps);
				return;
			}
			setSteps((prev) => [...prev, step]);
		},
		[steps]
	);

	const removeStep = useCallback((stepId: string): void => {
		setSteps((prev) => prev.filter((step) => step.id !== stepId));
	}, []);

	const value = {
		steps: [getOriginStep(), ...steps].filter(Boolean),
		setOriginStep,
		addStep,
		removeStep,
	};

	return (
		<NavigationBreadcrumbsContext.Provider value={value}>
			{children}
		</NavigationBreadcrumbsContext.Provider>
	);
};
