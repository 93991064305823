import {
	Flex,
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CompletedAssignments } from './progress/CompletedAssignments';
import { CurrentAssignments } from './progress/CurrentAssignments';
import { HistoryTable } from '../../../user-history/components/HistoryTable';
import { UserPerformanceChart } from './performance/UserPerformanceChart';
import { UserPerformanceTable } from './performance/UserPerformanceTable';
import { UserDetailsOverview } from './overview/UserDetailsOverview';
import { useActiveTab } from '../../../../common/hooks/useActiveTab';
import { StoryStatus } from '../../../../common/hooks/useStoryStatus';
import { ReactElement } from 'react';

interface UserDetailsProps {
	userId?: string;
	userName?: string;
	renderCurrentAssignmentStoryCardButton: (
		storyId: string,
		status?: StoryStatus
	) => ReactElement;
	renderCompletedAssignmentStoryCardButton: (
		storyId: string,
		status?: StoryStatus
	) => ReactElement;
}
export const UserDetails = ({
	userId,
	userName,
	renderCurrentAssignmentStoryCardButton,
	renderCompletedAssignmentStoryCardButton,
}: UserDetailsProps) => {
	const { t } = useTranslation(['dashboard', 'common']);
	const { activeTab, setActiveTab } = useActiveTab();
	const navigate = useNavigate();

	return (
		<Tabs
			isLazy
			index={activeTab ?? 0}
			onChange={(index) => {
				setActiveTab(index === 0 ? null : index, 'replaceIn');
			}}
		>
			<TabList>
				<Tab
					fontWeight={'medium'}
					_selected={{ color: '#4241E4' }}
					fontSize={'16px'}
				>
					{t('dashboard:user_details.overview')}
				</Tab>
				<Tab
					fontWeight={'medium'}
					_selected={{ color: '#4241E4' }}
					fontSize={'16px'}
				>
					{t('dashboard:user_details.todo')}
				</Tab>
				<Tab
					fontWeight={'medium'}
					_selected={{ color: '#4241E4' }}
					fontSize={'16px'}
				>
					{t('dashboard:user_details.achievements')}
				</Tab>
				<Tab
					fontWeight={'medium'}
					_selected={{ color: '#4241E4' }}
					fontSize={'16px'}
				>
					{t('dashboard:user_details.activity')}
				</Tab>
				<Tab
					fontWeight={'medium'}
					_selected={{ color: '#4241E4' }}
					fontSize={'16px'}
				>
					{t('dashboard:user_details.performance')}
				</Tab>
			</TabList>
			<TabIndicator mt="-1.5px" height="2px" bg="#4241E4" borderRadius="1px" />
			<TabPanels>
				<TabPanel p="0" py="8">
					<UserDetailsOverview userId={userId} />
				</TabPanel>
				<TabPanel p="0">
					<CurrentAssignments
						userId={userId}
						renderStoryCardButton={renderCurrentAssignmentStoryCardButton}
					/>
				</TabPanel>
				<TabPanel p="0">
					<CompletedAssignments
						userId={userId}
						renderStoryCardButton={renderCompletedAssignmentStoryCardButton}
					/>
				</TabPanel>
				<TabPanel p="0">
					<HistoryTable
						userId={userId}
						onRowClick={(row) => {
							navigate(
								`/dashboard/user/${userId ?? ''}/session/${row.sessionId}?activeCallTabIndex=2`
							);
						}}
					/>
				</TabPanel>
				<TabPanel p="0">
					<Flex direction={{ base: 'column' }} gap="5" mt="8">
						{userId && (
							<UserPerformanceChart userId={userId} userName={userName} />
						)}
						<UserPerformanceTable />
					</Flex>
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
};
