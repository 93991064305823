import { Flex } from '@chakra-ui/react';
import React from 'react';
import { UserWidgets } from '../../common/components/UserWidgets';
import { NavigationBreadCrumbs } from '../../common/components/app/navigation/NavigationBreadCrumbs';
import { BreadCrumbProps } from '../../common/components/core/breadcrumbs/BreadCrumbs';

export interface BasePageNavigationProps {
	breadcrumbsProps?: BreadCrumbProps;
	fullWidthLayout?: boolean;
}
export const BasePageNavigation: React.FC<BasePageNavigationProps> = ({
	breadcrumbsProps,
	fullWidthLayout = false,
}) => {
	return (
		<Flex
			direction="row"
			justifyContent={breadcrumbsProps ? 'space-between' : 'flex-end'}
			alignItems="center"
			width="100%"
			px={fullWidthLayout ? 10 : 'initial'}
		>
			{!!breadcrumbsProps && <NavigationBreadCrumbs {...breadcrumbsProps} />}
			<UserWidgets />
		</Flex>
	);
};
