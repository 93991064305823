import { ArrayParam, useQueryParam, withDefault } from 'use-query-params';

export const useShownUsers = () => {
	const [shownUsers, setShownUsers] = useQueryParam<(string | null)[]>(
		'shownUsers',
		withDefault(ArrayParam, [])
	);

	return { shownUsers, setShownUsers };
};
