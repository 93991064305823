import { Button, ButtonProps } from '@chakra-ui/react';

interface StoryCardButtonProps extends ButtonProps {
	label: string;
}

export const StoryCardButton = ({ label, ...props }: StoryCardButtonProps) => {
	return (
		<Button
			w={'full'}
			id="exit-button"
			borderRadius={'12px'}
			color={'#4241E4'}
			bg={'white'}
			_hover={{ bg: 'white' }}
			border={'1px solid #4241E4'}
			px="6"
			width="100%"
			{...props}
		>
			{label}
		</Button>
	);
};
