import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { SchemaOrganizationResponseDto } from '@jam/api-sdk/api';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useGetSagasQuery } from '../../redux/api/adminApi';

const orgSchema = z.object({
	id: z.string(),
	name: z.string().min(1),
	sagaIds: z.array(z.string()),
});

type Org = z.infer<typeof orgSchema>;

export const OrganizationEditModal = ({
	org,
	isOpen,
	onClose,
	onSubmit,
}: {
	org: SchemaOrganizationResponseDto;
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (data: Org) => Promise<void>;
}) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isValid, isSubmitting },
	} = useForm<Org>({
		resolver: zodResolver(orgSchema),
		defaultValues: org,
	});
	const { data: sagasData } = useGetSagasQuery();

	useEffect(() => {
		reset(org);
	}, [org]);

	const onCloseHandler = () => {
		reset(org);
		onClose();
	};

	const options = (sagasData ?? []).map((saga) => ({
		label: saga.name,
		value: saga.id,
	}));

	return (
		<Modal size={'lg'} isOpen={isOpen} onClose={onCloseHandler}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize={'2xl'}>Edit organization</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={5}>
					<FormControl as="fieldset">
						<input type="hidden" {...register('id')} />
						<FormLabel>Name</FormLabel>
						<Input borderRadius={'full'} {...register('name')} />
						<FormLabel>Sagas</FormLabel>
						<select {...register('sagaIds')} multiple>
							{options.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</select>
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button
						id="submit-org-data"
						type="submit"
						borderRadius={'full'}
						bgColor={isValid ? 'brand.500' : 'gray.500'}
						isLoading={isSubmitting}
						isDisabled={!isValid}
						size="md"
						onClick={() => {
							if (!isValid) return;

							void handleSubmit(onSubmit)();
							onCloseHandler();
						}}
						mr={3}
					>
						Submit
					</Button>
					<Button
						borderRadius={'32px'}
						textColor={'black'}
						bg={'white'}
						gap={'16px'}
						border={'1px'}
						_hover={{ bg: 'white' }}
						onClick={() => {
							onCloseHandler();
						}}
					>
						<Text>Cancel</Text>
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
