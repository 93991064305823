import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoryCardButton } from '../StoryCardButton';

export interface StartStoryButtonProps {
	storyId: string;
}

export const StartStoryButton = ({ storyId }: StartStoryButtonProps) => {
	const { t } = useTranslation('story');
	const navigate = useNavigate();

	return (
		<StoryCardButton
			label={t('start_mission')}
			onClick={() => {
				navigate(`/call?storyId=${storyId}`);
			}}
		/>
	);
};
