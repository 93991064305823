import { Flex } from '@chakra-ui/react';
import React from 'react';
import { AllTimeCompletions } from './components/AllTimeCompletions';
import { KeyImprovementStories } from './components/KeyImprovementStories';
import { RecentActivity } from './components/RecentActivity';

interface Props {
	userId?: string;
}

export const UserDetailsOverview: React.FC<Props> = ({ userId }: Props) => {
	if (!userId) return null;

	return (
		<Flex direction="column" gap="8" mt="8">
			<AllTimeCompletions userId={userId} />
			<KeyImprovementStories userId={userId} />
			<RecentActivity userId={userId} />
		</Flex>
	);
};
