import { Flex } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { TourProvider } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { OnboardingSteps } from '../common/components/onboarding/OnboardinSteps';
import { OnboardingModal } from '../common/components/onboarding/OnboardingModal';
import { OnboardingNavigation } from '../common/components/onboarding/OnboardingNavigation';
import { useIsRecruiting } from '../common/hooks/useIsRecruiting';
import { OnboardingFeature } from '../common/types/Onboarding';
import { GetInspired } from '../features/home/components/GetInspired';
import { KeepLearning } from '../features/home/components/KeepLearning';
import { NextStory } from '../features/home/components/NextStory';
import { UserWeeklyProgress } from '../features/home/components/UserWeeklyProgress';
import { UsersLeaderboard } from '../features/home/components/UsersLeaderboard';
import { useAppSelector } from '../redux/hooks';
import { selectLanguage } from '../redux/slice';
import { useOriginPageBreadcrumbs } from '../common/hooks/useOriginPageBreadcrumbs';
import { BasePage } from './common/BasePage';

export const HomePage = () => {
	const { user: authUser } = useAuthInfo();
	const { t } = useTranslation('home');
	const language = useAppSelector(selectLanguage);
	const day = new Date().toLocaleDateString(language, { weekday: 'long' });
	const isRecruiting = useIsRecruiting();

	const steps = useOriginPageBreadcrumbs(
		'home-dashboard',
		'/users/home',
		t('home')
	);

	return (
		<TourProvider
			steps={OnboardingSteps}
			components={{
				Navigation: () => (
					<OnboardingNavigation feature={OnboardingFeature.HOME_PAGE} />
				),
			}}
		>
			<BasePage
				title={t('salutation', { day, name: authUser?.firstName || '' })}
				breadcrumbsProps={{
					items: steps,
				}}
			>
				<Flex direction={'column'} gap="10">
					<OnboardingModal />
					<NextStory />
					<Flex gap="8">
						<Flex
							direction={'column'}
							gap="10"
							w={isRecruiting ? '100%' : 'calc(100% - 270px)'}
						>
							<KeepLearning />
							{!isRecruiting && <GetInspired />}
						</Flex>
						{!isRecruiting && (
							<Flex direction={'column'} gap="4" w="240px" flexShrink={0}>
								<UserWeeklyProgress />
								<UsersLeaderboard />
							</Flex>
						)}
					</Flex>
				</Flex>
			</BasePage>
		</TourProvider>
	);
};
