import {
	SchemaAssignedContentResponseDto,
	SchemaCreateAssignContentRequestDto,
} from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'content-allocation-api',
	tagTypes: ['assignments'],
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		getAssignedContentAllocation: builder.query<
			SchemaAssignedContentResponseDto[],
			string
		>({
			providesTags: ['assignments'],
			query: (userId: string) => `assignments/${userId}`,
		}),
		getAllContentAllocations: builder.query<
			SchemaAssignedContentResponseDto[],
			string
		>({
			providesTags: ['assignments'],
			query: (userId: string) => `assignments?userIds[]=${userId}`,
		}),
		getCompletedAssignedContentAllocation: builder.query<
			SchemaAssignedContentResponseDto[],
			string
		>({
			providesTags: ['assignments'],
			query: (userId: string) =>
				`assignments?userIds[]=${userId}&completed=true`,
		}),
		assignContent: builder.mutation<void, SchemaCreateAssignContentRequestDto>({
			invalidatesTags: ['assignments'],
			query: ({ userIds, reference }) => ({
				url: `assignments`,
				method: 'POST',
				body: {
					userIds,
					reference,
				},
			}),
		}),
		skipAssignment: builder.mutation<void, string>({
			invalidatesTags: ['assignments'],
			query: (id: string) => ({
				url: `assignments/skip/${id}`,
				method: 'PATCH',
			}),
		}),
	}),
});

export const {
	useGetAllContentAllocationsQuery,
	useGetAssignedContentAllocationQuery,
	useGetCompletedAssignedContentAllocationQuery,
	useAssignContentMutation,
} = api;
export default api;
