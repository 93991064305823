import { Flex, Progress, Skeleton, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useGetWeeklyUserXp } from '../hooks/xpHooks';

const WEEKLY_TARGET_XP = 250;

const LoadingSkeleton = () => (
	<VStack role="placeholder" align="stretch" spacing={2}>
		<Skeleton height="50px" />
	</VStack>
);

export const UserWeeklyProgress = () => {
	const { t } = useTranslation();
	const current = useGetWeeklyUserXp();

	if (current === undefined) return <LoadingSkeleton />;

	return (
		<VStack
			align="stretch"
			bg={'#FAFAFA'}
			p="4"
			borderRadius={'16px'}
			spacing={2}
		>
			<Text fontWeight="500" fontSize="16px" color="#0b1215">
				{t('your_weekly_target')}
			</Text>
			<Progress
				value={(Math.max(current, 5) / WEEKLY_TARGET_XP) * 100}
				height="8px"
				borderRadius="full"
				variant="yellow"
			/>
			<Flex justifyContent="space-between">
				<Text color="#757575" fontSize={'14px'}>
					{t('progress_this_week')}
				</Text>
				<Text color="#757575" fontSize={'14px'}>
					{current}/{WEEKLY_TARGET_XP}XP
				</Text>
			</Flex>
		</VStack>
	);
};
