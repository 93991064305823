import React, { createContext, useEffect, useRef, useState } from 'react';
import {
	NavigationType,
	useLocation,
	useNavigationType,
} from 'react-router-dom';

export type Location = {
	pathname: string;
	key: string;
	state: any;
	search: string;
};
export type IHistoryContext = {
	history: Location[];
};

export const HistoryContext = createContext<IHistoryContext>({
	history: [],
});

export const AppHistoryProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [history, setHistory] = useState<Location[]>([]);
	const location = useLocation();
	const navType = useNavigationType();
	const isInitialMount = useRef(true);

	useEffect(() => {
		if (isInitialMount.current) {
			// Handle initial load - always add to history
			setHistory([location]);
			isInitialMount.current = false;
			return;
		}

		setHistory((prev) => {
			// Same location, don't add to history
			if (
				prev.length > 0 &&
				prev[prev.length - 1].pathname === location.pathname
			) {
				return prev;
			}

			switch (navType) {
				case NavigationType.Pop:
					return prev.slice(0, prev.length - 1);
				case NavigationType.Replace:
					return [...prev.slice(0, prev.length - 1), location];
				case NavigationType.Push:
				default:
					return [...prev, location];
			}
		});
	}, [location, navType]);

	return (
		<HistoryContext.Provider value={{ history }}>
			{children}
		</HistoryContext.Provider>
	);
};
