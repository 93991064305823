import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AppHistoryProvider } from '../../providers/history/AppHistoryProvider';
import { NavigationBreadcrumbsProvider } from '../../providers/breadcrumbs/NavigationBreadcrumbsProvider';

interface MemoizedOutletProps {
	accessToken: string;
}

export const MemoizedOutlet = memo<MemoizedOutletProps>(
	() => (
		<QueryParamProvider adapter={ReactRouter6Adapter}>
			<AppHistoryProvider>
				<NavigationBreadcrumbsProvider>
					<Outlet />
				</NavigationBreadcrumbsProvider>
			</AppHistoryProvider>
		</QueryParamProvider>
	),
	(prevProps, nextProps) => prevProps.accessToken === nextProps.accessToken
);

MemoizedOutlet.displayName = 'MemoizedOutlet';
