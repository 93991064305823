import { Flex, Skeleton, Text } from '@chakra-ui/react';
import { useGetStoriesQuery } from '../../../../../redux/api/cmsApi';
import { useTranslation } from 'react-i18next';
import { useGetUserPerformanceByRoundQuery } from '../../../../../redux/api/dashboardApi';
import { useGetManageableUsersQuery } from '../../../../../redux/api/crmApi';
import { useActiveMission } from '../../../hooks/useActiveMission';
import { useShownUsers } from '../../../hooks/useShownUsers';
import { PerformanceChart } from '../../../../../common/components/PerformanceChart';

const LoadingSkeleton = () => {
	return <Skeleton role="placeholder" height="20px" />;
};
interface UserPerformanceChartProps {
	userId: string;
	userName: string | undefined;
}

export const UserPerformanceChart = ({
	userId,
	userName,
}: UserPerformanceChartProps) => {
	const { activeMission, setActiveMission } = useActiveMission();
	const { shownUsers, setShownUsers } = useShownUsers();
	const allSelectedUsers = [userId, ...shownUsers];
	const { t } = useTranslation('dashboard');

	const { data: missions } = useGetStoriesQuery();
	const { data: teamMembers } = useGetManageableUsersQuery();

	const { data: apiPerformanceData } = useGetUserPerformanceByRoundQuery(
		{
			users: allSelectedUsers.filter((uid) => uid !== null),
			missionId: activeMission ?? '',
		},
		{
			skip: !userId,
		}
	);

	if (!apiPerformanceData || !missions) return <LoadingSkeleton />;

	const selectedMission = missions.find(
		(mission) => mission.id === activeMission
	);

	const allEmpty = apiPerformanceData.every((x) => x[userId]?.score === null);

	// Transform API performance data into chart-ready format
	// - Replace null scores with -1 to indicate no data
	// - Add entries for other selected users with -1 scores if they haven't played that round
	const data = apiPerformanceData.slice().map((x) => ({
		round: x[userId]?.round ?? 0,
		[userId]: {
			...x[userId],
			score: x[userId]?.score ?? -1,
		},
		...Object.fromEntries(
			allSelectedUsers
				.filter((uid) => uid !== userId && uid !== null) // Exclude main user
				.map((uid) => [
					uid as string,
					{
						...x[uid!],
						score: x[uid!]?.score ?? -1,
					},
				]) // Use existing score if available, otherwise -1
		),
	}));

	return (
		<Flex direction={{ base: 'column' }} gap="5" id="performance-chart">
			<Text fontSize={'20px'} fontWeight={'500'}>
				{t('user_details.performance_chart.compare_performance', { userName })}
			</Text>
			<PerformanceChart
				data={data}
				alwaysShownUserId={userId}
				shownUsers={allSelectedUsers.filter((uid) => uid !== null)}
				teamMembers={teamMembers ?? []}
				selectedMission={selectedMission}
				missions={missions ?? []}
				onMissionChange={setActiveMission}
				onUsersChange={setShownUsers}
				allEmpty={allEmpty}
			/>
		</Flex>
	);
};
