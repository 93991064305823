import {
	Box,
	Button,
	Image,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import {
	PathsApiV1SessionsGetParametersQueryState,
	SchemaSessionResponseDto,
	SessionResponseDTOCallEndedReason,
} from '@jam/api-sdk/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type CallEndedReasonModalProps = {
	session: SchemaSessionResponseDto | undefined | null;
};

export const CallEndedReasonModal = ({
	session,
}: CallEndedReasonModalProps) => {
	const { t } = useTranslation('call', { keyPrefix: 'ended_reason_modal' });
	const [isOpen, setIsOpen] = useState<boolean>(false);

	useEffect(() => {
		if (
			!session ||
			!session.state ||
			session.state !== PathsApiV1SessionsGetParametersQueryState.DONE
		) {
			return;
		}

		switch (session.call.endedReason) {
			case SessionResponseDTOCallEndedReason.assistant_ended_call:
			case SessionResponseDTOCallEndedReason.assistant_said_end_call_phrase:
			case SessionResponseDTOCallEndedReason.customer_ended_call:
				return;
			case SessionResponseDTOCallEndedReason.exceeded_max_duration:
			case SessionResponseDTOCallEndedReason.silence_timed_out:
				setIsOpen(true);
				return;
			default:
				setIsOpen(true);
				return;
		}
	}, [session]);

	const renderContent = () => {
		switch (session?.call.endedReason) {
			case SessionResponseDTOCallEndedReason.exceeded_max_duration:
				return t('exceeded_max_duration');
			case SessionResponseDTOCallEndedReason.silence_timed_out:
				return t('silence_timed_out');
			default:
				return t('unexpected');
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={() => setIsOpen(false)} isCentered>
			<ModalOverlay />
			<ModalContent maxW="450px" py={6} borderRadius={'24px'}>
				<ModalBody p={0}>
					<VStack spacing={4} align="center">
						<Image src="/icons/mic-icon.svg" alt="Jam Logo" w="48px" h="48px" />
						<Text mx={6} fontSize="xl" fontWeight="bold">
							{t('title')}
						</Text>
						<Text mx={6} textAlign="center" color="gray.600">
							{renderContent()}
						</Text>
						<Box width={'full'} px={6}>
							<Button
								px={6}
								w="full"
								colorScheme="brand"
								bg="brand.500"
								onClick={() => setIsOpen(false)}
								boxShadow="none"
								outline={'none'}
								_focus={{ outline: 'none', boxShadow: 'none' }}
							>
								{t('ok')}
							</Button>
						</Box>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
