import { Box, Flex, Progress, Text } from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk/api';
import { calculateJourneyCompletion } from '../../utils/calculateCompletion';
import { JourneyLabels } from '../JourneyLabels';
import { GraduationCap } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';

type TrackDetailHeaderProps = {
	journey: SchemaJourneyResponseDto;
	completedStoryIds?: string[];
	hideStatus?: boolean;
};

export const TrackDetailHeader = ({
	journey,
	completedStoryIds,
	hideStatus,
}: TrackDetailHeaderProps) => {
	const { t } = useTranslation('common');

	const completion =
		completedStoryIds && !hideStatus
			? calculateJourneyCompletion(journey, completedStoryIds)
			: undefined;

	return (
		<Box w={'100%'}>
			<Flex
				direction="row"
				w={'100%'}
				justifyContent={'space-between'}
				paddingBottom="12px"
			>
				<Text
					data-testid={`track_header_title_${journey.id}`}
					fontSize="24px"
					fontWeight="500"
					textAlign="left"
				>
					{journey.title}
				</Text>
				{completion && completion?.completed === completion?.total && (
					<Flex
						direction="row"
						alignItems="center"
						gap="2"
						sx={{
							borderRadius: '4px',
							backgroundColor: '#F5F5F5',
						}}
						px="8px"
						py="4px"
					>
						<GraduationCap color="#147129" />
						<Text
							fontSize="16px"
							fontWeight="400"
							color="#0B1215"
							textAlign="left"
						>
							{t('track_detail.header.certified')}
						</Text>
					</Flex>
				)}
			</Flex>
			<Flex
				direction="row"
				w={'100%'}
				justifyContent={'space-between'}
				paddingBottom="16px"
			>
				<Flex w="60%">
					<Text
						fontSize="16px"
						fontWeight="400"
						color="gray.500"
						textAlign="left"
					>
						{journey.description}
					</Text>
				</Flex>
				<Flex h="auto" />
			</Flex>

			<Flex
				direction="row"
				w="100%"
				justifyContent="space-between"
				paddingBottom="20px"
				alignItems="center"
			>
				<Flex>
					<JourneyLabels journey={journey} />
				</Flex>

				{completion && (
					<Flex gap={'2'} alignItems="end" direction="column" w="260px">
						<Text fontSize="14px" fontWeight="400" color="#757575">
							{completion.completed}/{completion.total}{' '}
							{t('track_detail.header.missions_completed')}
						</Text>
						<Progress
							w="100%"
							h="6px"
							borderRadius={'20px'}
							size={'lg'}
							value={completion.completed}
							max={completion.total}
						/>
					</Flex>
				)}
			</Flex>
		</Box>
	);
};
