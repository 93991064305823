import { useGetStoryLastSessionQuery } from '../../../redux/api/sessionApi';
import { useGetStoryUserFeedbackQuery } from '../../../redux/api/userFeedbackApi';
import { useAppSelector } from '../../../redux/hooks';
import { selectHasUserMadeCall } from '../../../redux/slice';
import { useActiveStory } from '../../home/hooks/useActiveStory';

export const useShouldAskForFeedback = () => {
	const hasUserMadeCall = useAppSelector(selectHasUserMadeCall);
	const { activeStory } = useActiveStory();
	const { data: userFeedback, isLoading: userFeedbackLoading } =
		useGetStoryUserFeedbackQuery(activeStory ?? '', {
			skip: !activeStory,
		});

	const { data: lastSession } = useGetStoryLastSessionQuery(
		activeStory as string,
		{
			skip: !activeStory || !hasUserMadeCall,
		}
	);

	// if the user has not made a call, we should not ask for feedback yet
	if (!hasUserMadeCall || !lastSession || !activeStory) return false;

	// only ask for feedback if the last session is completed and has items
	if (!lastSession.completed) return false;

	// only ask for feedback if the user has not already given feedback
	if (userFeedbackLoading || (userFeedback && userFeedback.meta.total > 0))
		return false;

	return true;
};
