import {
	Avatar,
	Box,
	Button,
	Divider,
	Flex,
	Stack,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import {
	SchemaSessionResponseDto,
	SchemaStoryResponseDto,
	SessionResponseDTOAnalysisState,
	SessionResponseDTOCallMessagesRole,
} from '@jam/api-sdk/api';
import { Info } from '@phosphor-icons/react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserQuery } from '../../../redux/api/crmApi';
import { useAppSelector } from '../../../redux/hooks';
import { selectCurrentCallStatus } from '../../../redux/slice';
import { formatSecondsToMinutesAndSeconds } from '../utils/formatSecondsToMinutesAndSeconds';
import { CallStatus } from './CallStatus';
import { KpiStats } from './KpiStat';
import { ReportAnIssueModal } from './ReportAnIssueModal';

const FALLBACK_AVATAR_URL = '/avatars/avatar.png';

type TranscriptProps = {
	persona: SchemaStoryResponseDto['persona'] | null | undefined;
	session: SchemaSessionResponseDto | null | undefined;
};

export const Transcript = ({ session, persona }: TranscriptProps) => {
	const { data: user } = useGetUserQuery(session?.userId ?? skipToken);
	const { t } = useTranslation('call');
	const callStatus = useAppSelector(selectCurrentCallStatus);
	const [seconds, setSeconds] = useState(0);
	const isCallOngoing = callStatus === CallStatus.started;
	let text = isCallOngoing ? t('call_is_being_transcribed') : t('transcript');
	const [reportAnIssueModalOpen, setReportAnIssueModalOpen] = useState(false);

	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	useEffect(() => {
		if (isCallOngoing) {
			intervalRef.current = setInterval(() => {
				setSeconds((s) => s + 1);
			}, 1000);
		} else {
			setSeconds(0);
		}

		return () => {
			if (intervalRef.current) clearInterval(intervalRef.current);
		};
	}, [callStatus]);

	const messages = session?.call?.messages.slice().sort((a, b) => {
		return a.secondsFromStart - b.secondsFromStart;
	});

	const renderTimer = () => {
		// time format to MM:SS
		const m = Math.floor(seconds / 60);
		const s = seconds % 60;
		const mm = m < 10 ? `0${m}` : m;
		const ss = s < 10 ? `0${s}` : s;

		return (
			<Box my="2" px="5">
				<Divider />
				<Flex mt="2" alignItems={'center'} gap="1">
					<Box w="10px" h="10px" borderRadius={'24px'} bg="#FF6767"></Box>
					<Text fontSize={'12px'}>{`${mm}:${ss}`}</Text>
				</Flex>
			</Box>
		);
	};
	const hasMessages = (session?.call?.messages?.length ?? 0) > 0;

	if (!hasMessages) {
		text = t('transcript_not_available');
	}

	const handleReportAnIssue = () => () => {
		setReportAnIssueModalOpen(true);
	};

	const userName = user?.firstName ?? 'user';
	const userAvatar = user?.pictureUrl ?? FALLBACK_AVATAR_URL;
	const personaName = persona?.name ?? 'bot';
	const personaAvatar =
		persona?.avatar_file.sizes.thumbnail?.url ??
		persona?.avatar_file.url ??
		FALLBACK_AVATAR_URL;

	return (
		<>
			{session && (
				<ReportAnIssueModal
					sessionId={session.id}
					isOpen={reportAnIssueModalOpen}
					onClose={() => setReportAnIssueModalOpen(false)}
				/>
			)}
			<Tooltip
				p={3}
				isDisabled={hasMessages}
				lineHeight={'21px'}
				hasArrow
				maxW={'200px'}
				placement="top"
				bg={'#0B1215'}
				borderRadius={'24px'}
				fontSize={'12px'}
				border={'1px solid #E2E8F0'}
				label={t('complete_one_attempt_transcript')}
			>
				<Box
					bg={hasMessages ? 'white' : '#FAFAFA'}
					cursor={hasMessages ? '' : 'pointer'}
					border={'1px'}
					pt="3"
					h="fit-content"
					mt={4}
					borderRadius={'24px'}
					borderColor={'#D9D9D9'}
					width="500px"
				>
					<Flex
						px="5"
						mb="2"
						alignItems="center"
						justifyContent="space-between"
					>
						<Flex alignItems="center" gap="2">
							{!hasMessages && (
								<Box
									cursor={'pointer'}
									border={'1px'}
									p="2"
									borderRadius={'24px'}
									borderColor={'#D9D9D9'}
								>
									<Info size={20} />
								</Box>
							)}
							<Text
								fontWeight={hasMessages ? 'medium' : 'normal'}
								fontSize={hasMessages ? '16px' : '14px'}
								color={hasMessages ? 'black' : '#757575'}
							>
								{text}
							</Text>
						</Flex>
						{session && (
							<Button
								variant="ghost"
								textColor={'#757575'}
								onClick={handleReportAnIssue()}
								fontSize={'10px'}
							>
								{t('report_an_issue')}
							</Button>
						)}
					</Flex>
					{isCallOngoing && renderTimer()}
					{!isCallOngoing && (
						<Box>
							{session?.call.recordingUrl && (
								<Box mt="2" px="5">
									<audio
										style={{ width: '100%' }}
										controls
										src={session.call.recordingUrl}
									/>
								</Box>
							)}

							{hasMessages && (
								<>
									<Stack
										px="5"
										maxH={'350px'}
										overflowY={'auto'}
										mt="3"
										direction="column"
										p="2"
									>
										{messages?.map((message, index) => {
											const isBot =
												message.role ===
												SessionResponseDTOCallMessagesRole.prospect;
											const imageUrl = !isBot ? userAvatar : personaAvatar;
											const name = !isBot ? userName : personaName;
											const rowOrder = !isBot ? 'row-reverse' : 'row';
											return (
												<Flex
													alignItems={isBot ? 'flex-start' : 'flex-end'}
													direction={rowOrder}
													gap="2"
													key={index}
													my={2}
												>
													<Avatar
														mt={isBot ? 4 : 0}
														size="sm"
														src={imageUrl ?? ''}
														name={name ?? ''}
													/>
													<Flex
														direction={'column'}
														alignItems={isBot ? 'flex-start' : 'flex-end'}
													>
														<Text fontSize={'12px'}>
															{formatSecondsToMinutesAndSeconds(
																message.secondsFromStart
															)}
														</Text>
														<Flex
															bgColor={'#F5F5F5'}
															px="4"
															py="2"
															w={'100%'}
															maxW={'220px'}
															borderTopRightRadius={'8px'}
															borderBottomRightRadius={isBot ? '8px' : '0'}
															borderTopLeftRadius={!isBot ? '8px' : '0'}
															borderBottomLeftRadius={'8px'}
															direction={'column'}
														>
															<Text color={'#757575'} fontSize={'12px'} my="1">
																{message.content}
															</Text>
														</Flex>
													</Flex>
												</Flex>
											);
										})}
									</Stack>
									{session?.kpis &&
										session.kpis.state ===
											SessionResponseDTOAnalysisState.DONE && (
											<KpiStats kpis={session.kpis} />
										)}
								</>
							)}
						</Box>
					)}
				</Box>
			</Tooltip>
		</>
	);
};
