import { SchemaSessionResponseDto } from '@jam/api-sdk/api';
import { useGetSessionsByUserIdQuery } from '../../../redux/api/sessionApi';

export const useGetLearnerLastSessionPerStory = (
	storyId: string | undefined,
	userId: string | null
): SchemaSessionResponseDto | undefined | null => {
	const { data, isLoading } = useGetSessionsByUserIdQuery(userId || '', {
		skip: !userId,
	});

	if (!userId || !storyId) return undefined;

	if (isLoading || !data) return undefined;

	const sessions = data.filter(
		(session) => session.storyReferenceId === storyId
	);

	if (!sessions.length) return null;

	// order sessions by createdAt date
	sessions.sort((a, b) => {
		return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
	});

	return sessions[sessions.length - 1];
};
