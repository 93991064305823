import { CONFIG } from './config';

// If you're testing locally and you want to see sentry errors,
// then you'll have to overwrite the env here, otherwise it will be empty
export function getReactAppEnv(): string {
	return (import.meta.env.VITE_ENV as string) || 'local';
}

// Here we can set which envs we want sentry enabled on
export function currentEnvNeedsSentry(): boolean {
	const env = getReactAppEnv();
	return CONFIG.sentryEnabledEnvironments.includes(env);
}

// Here we can set which envs we want sentry enabled on
export function currentEnvNeedsAmplitude(): boolean {
	// const env = getReactAppEnv();
	// Leave this enabled for now @TODO: remove this
	return false;
	//return CONFIG.amplitudeEnabledEnvironments.includes(env);
}

export const delay = (t: number): Promise<void> =>
	new Promise((resolve) => setTimeout(resolve, t));
