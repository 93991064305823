import {
	Button,
	Flex,
	FormControl,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	Textarea,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Flag } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';
import {
	RequestTypeId,
	useReportIssueMutation,
} from '../../redux/api/commonApi';
import {
	selectReportIssueModalIsOpen,
	setReportIssueModalIsOpen,
} from '../../redux/slice';
import { AppDispatch } from '../../redux/store';
import { Chip } from 'front-library';

const ISSUE_ELEMENTS: Record<string, string> = {
	app_keeps_crashing: 'app_keeps_crashing',
	cannot_start_roleplay: 'cannot_start_roleplay',
	button_is_broken: 'button_is_broken',
	audio_not_working: 'audio_not_working',
	app_is_confusing: 'app_is_confusing',
};

type ReportIssueForm = {
	issues: string[];
	feedback: string;
};

const ReportIssueSchema = z.object({
	issues: z.array(
		z.enum(Object.values(ISSUE_ELEMENTS) as [string, ...string[]])
	),
	feedback: z.string().min(1),
});

export const ReportIssueModal = () => {
	const { t } = useTranslation('common');
	const { user } = useAuthInfo();
	const [reportIssue] = useReportIssueMutation();
	const reportIssueModalIsOpen = useSelector(selectReportIssueModalIsOpen);
	const dispatch = useDispatch<AppDispatch>();

	const { register, handleSubmit, watch, getValues, setValue } =
		useForm<ReportIssueForm>({
			resolver: zodResolver(ReportIssueSchema),
			defaultValues: {
				issues: [],
				feedback: '',
			},
		});

	const onClose = () => {
		setValue('issues', []);
		setValue('feedback', '');
		dispatch(setReportIssueModalIsOpen(false));
	};

	const onItemClicked = (issue: string) => {
		const issues = getValues('issues');
		if (issues.includes(issue)) {
			setValue(
				'issues',
				issues.filter((i) => i !== issue)
			);
		} else {
			setValue('issues', [...issues, issue]);
		}
	};

	const onSubmit = async (data: ReportIssueForm) => {
		if (!user) return;
		const issues = data.issues.join(',');
		const feedback = data.feedback;
		const currentUrl = window.location.href;

		const summary = `User reported an issue: ${issues}`;
		const description = `User reported an issue: 
		\n ${feedback} 
		\n User email: ${user.email} 
		\n User first name: ${user.firstName ?? ''} 
		\n User last name: ${user.lastName ?? ''} 
		\n Current URL: ${currentUrl}`;

		await reportIssue({
			summary,
			description,
			requestTypeId: RequestTypeId.BUG_REPORT,
		});
		onClose();
	};

	const feedback = watch('feedback');
	const issues = watch('issues');

	return (
		<Modal size={'lg'} isOpen={reportIssueModalIsOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize={'16px'}>
					<Flex alignItems={'center'} gap="2">
						<Flag weight="bold" size={24} />
						{t('report_issue_modal.title')}
					</Flex>
					<Text mt="2" fontWeight={'normal'} fontSize={'13px'} color="#757575">
						{t('report_issue_modal.subtitle')}
					</Text>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={5}>
					<Stack spacing={3}>
						<Flex flexWrap={'wrap'} gap="3">
							{Object.entries(ISSUE_ELEMENTS).map((element) => (
								<Chip
									onClick={() => onItemClicked(element[0])}
									key={element[0]}
									cursor={'pointer'}
									color={issues.includes(element[0]) ? '#4241E4' : '#757575'}
									border={'1px'}
									borderColor={
										issues.includes(element[0]) ? '#4241E4' : '#D9D9D9'
									}
									px="2"
									bg={
										issues.includes(element[0])
											? 'rgba(66, 65, 228, 1, 0.08)'
											: 'transparent'
									}
									borderRadius={'24px'}
									text={t(`report_issue_modal.issues.${element[1]}`)}
								/>
							))}
						</Flex>
						<FormControl as="fieldset">
							<Textarea
								{...register('feedback')}
								placeholder={t('report_issue_modal.placeholder') ?? ''}
								size="sm"
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						id="submit-negative-feedback-button"
						type="submit"
						borderRadius={'8px'}
						textColor={'white'}
						bg={feedback.length > 0 ? '#4241E4' : '#D9D9D9'}
						_hover={{ bg: feedback.length > 0 ? '#4241E4' : '#D9D9D9' }}
						colorScheme="blue"
						onClick={() => {
							if (feedback.length > 0) void handleSubmit(onSubmit)();
						}}
					>
						{t('submit')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
