import {
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useIsOwner } from '../common/hooks/useCheckRole';
import { useOriginPageBreadcrumbs } from '../common/hooks/useOriginPageBreadcrumbs';
import { InviteUsers } from '../features/dashboard/components/team/common/InviteUsers';
import { PendingInvites } from '../features/dashboard/components/team/pending-invites/PendingInvites';
import { RegisteredMembers } from '../features/dashboard/components/team/registered-members/RegisteredMembers';
import { ContentAssignmentTab } from '../features/settings/components/ContentAssignmentTab';
import { useGetOrgTeamsQuery } from '../redux/api/crmApi';
import { BasePage } from './common/BasePage';

export const TeamsPage = () => {
	const { t } = useTranslation(['team', 'settings']);
	const isOwner = useIsOwner();
	const { data: teams } = useGetOrgTeamsQuery();
	const steps = useOriginPageBreadcrumbs('teams', '/teams', t('team:team'));

	return (
		<BasePage
			title={t('team:team_members')}
			titleActions={<InviteUsers />}
			breadcrumbsProps={{
				items: steps,
			}}
		>
			<Tabs w={'100%'} isLazy>
				<TabList>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('team:registered_members')}
					</Tab>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('team:pending_invites')}
					</Tab>
					{isOwner && (
						<Tab
							fontWeight={'medium'}
							_selected={{ color: '#4241E4' }}
							fontSize={'16px'}
						>
							{t('settings:content-assignment')}
						</Tab>
					)}
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<TabPanels>
					<TabPanel px="0" py="8">
						<RegisteredMembers teams={teams ?? []} />
					</TabPanel>
					<TabPanel px="0" py="8">
						<PendingInvites />
					</TabPanel>
					{isOwner && (
						<TabPanel px="0" py="8">
							<ContentAssignmentTab />
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
		</BasePage>
	);
};
