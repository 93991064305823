import { configureStore } from '@reduxjs/toolkit';
import adminApi from './api/adminApi';
import cmsApi from './api/cmsApi';
import commonApi from './api/commonApi';
import contentAllocationApi from './api/contentAllocationApi';
import crmApi from './api/crmApi';
import dashboardApi from './api/dashboardApi';
import leaderboardApi from './api/leaderboardApi';
import sessionApi from './api/sessionApi';
import userFeedbackApi from './api/userFeedbackApi';

import { applicationStateSlice } from './slice';

export function buildStore() {
	return configureStore({
		reducer: {
			applicationState: applicationStateSlice.reducer,
			[sessionApi.reducerPath]: sessionApi.reducer,
			[contentAllocationApi.reducerPath]: contentAllocationApi.reducer,
			[userFeedbackApi.reducerPath]: userFeedbackApi.reducer,
			[dashboardApi.reducerPath]: dashboardApi.reducer,
			[leaderboardApi.reducerPath]: leaderboardApi.reducer,
			[cmsApi.reducerPath]: cmsApi.reducer,
			[crmApi.reducerPath]: crmApi.reducer,
			[commonApi.reducerPath]: commonApi.reducer,
			[adminApi.reducerPath]: adminApi.reducer,
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware()
				.concat(sessionApi.middleware)
				.concat(contentAllocationApi.middleware)
				.concat(userFeedbackApi.middleware)
				.concat(dashboardApi.middleware)
				.concat(leaderboardApi.middleware)
				.concat(cmsApi.middleware)
				.concat(crmApi.middleware)
				.concat(commonApi.middleware)
				.concat(adminApi.middleware),
	});
}
const store = buildStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
