import { SchemaJourneyResponseDto } from '@jam/api-sdk/api';

export const calculateJourneyCompletion = (
	journey: SchemaJourneyResponseDto,
	completedStoryIds: string[]
) => {
	// Filter out the completed stories that are in the journey
	const completedStoriesOnJourney = completedStoryIds.filter((storyId) =>
		journey.stories.find((story) => story.id === storyId)
	);

	// Calculate the percentage of completion
	const percentage = Math.round(
		(completedStoriesOnJourney.length / journey.stories.length) * 100
	);

	// Return the completion data
	return {
		completed: completedStoriesOnJourney.length,
		total: journey.stories.length,
		percentage,
	};
};
