/* eslint-disable @typescript-eslint/no-explicit-any */
import Select, {
	MultiValueProps,
	components,
	Props,
	GroupBase,
} from 'react-select';
import { Text } from '@chakra-ui/react';
const MoreSelectedBadge = ({ items }: { items: string[] }) => {
	const label = `+ ${items.length}`;
	return (
		<Text order={99} ml={'auto'} fontSize={'14px'}>
			{label}
		</Text>
	);
};

const MultiValue = ({
	index,
	getValue,
	...props
}: MultiValueProps<any, boolean, GroupBase<any>>) => {
	const maxToShow = 2;
	const overflow = (getValue() as { label: string }[])
		.slice(maxToShow)
		.map((x) => x.label);
	return index < maxToShow ? (
		<components.MultiValue {...props} index={index} getValue={getValue} />
	) : index === maxToShow ? (
		<MoreSelectedBadge items={overflow} />
	) : null;
};

type CustomSelectProps = Props;

export const CustomSelect = ({ ...props }: CustomSelectProps) => {
	return <Select {...props} components={{ MultiValue }} />;
};
