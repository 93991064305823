import { Text } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../redux/hooks';
import { setAccessToken, setLanguage } from '../../redux/slice';
import { RootState } from '../../redux/store';
import { logSetUserId, logUserInformation } from '../../utils/logger';
import { MemoizedOutlet } from './MemoizedOutlet';

export const LayoutPage = () => {
	const dispatch = useDispatch();
	const authInfo = useAuthInfo();
	const { accessToken, language } = useAppSelector(
		(state: RootState) => state.applicationState
	);

	useEffect(() => {
		dispatch(setAccessToken(authInfo?.accessToken ?? ''));
	}, [dispatch, authInfo]);

	useEffect(() => {
		if (authInfo.user) {
			const selectedLanguage = authInfo.user.properties?.language ?? 'German';

			dispatch(setLanguage(selectedLanguage === 'English' ? 'en' : 'de'));
		}
	}, [authInfo]);

	useEffect(() => {
		if (authInfo.user) {
			logUserInformation({
				'First Name': authInfo.user.firstName ?? '',
				'Last Name': authInfo.user.lastName ?? '',
				Email: authInfo.user.email ?? '',
			});
			logSetUserId(authInfo.user.userId);
		}
	}, [authInfo]);

	if (!authInfo.user || !language) {
		return <Text>Loading</Text>;
	}

	return <>{accessToken && <MemoizedOutlet accessToken={accessToken} />}</>;
};
