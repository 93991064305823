import {
	Box,
	Flex,
	SimpleGrid,
	Skeleton,
	SkeletonText,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useGetStoriesQuery } from '../../../redux/api/cmsApi';
import { StoryCard } from '../../../common/components/story-detail/StoryCard';
import { StartStoryButton } from '../../../common/components/story-detail/buttons/StartStoryButton';

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="4" aria-label="Loading stories">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
			</Flex>
		</Flex>
	);
};

export const AllStories = () => {
	const { t } = useTranslation(['gym', 'story']);
	const { data: stories, isLoading } = useGetStoriesQuery();

	if (isLoading) {
		return <LoadingState />;
	}

	if (!stories) {
		return null;
	}
	return (
		<Box my="10">
			<Flex mb="6" alignItems={'center'} gap="2">
				<Text
					w="fit-content"
					fontSize="2xl"
					color={'#757575'}
					fontWeight="semibold"
				>
					{t('all_missions', { count: stories.length })}
				</Text>
			</Flex>
			<SimpleGrid spacing={5} columns={[1, 1, 2, 3]}>
				{stories?.map((story) => (
					<StoryCard
						key={story.id}
						story={story}
						hideStatus={true}
						renderStoryButton={() => <StartStoryButton storyId={story.id} />}
					/>
				))}
			</SimpleGrid>
		</Box>
	);
};
