import { Flex, Skeleton, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SolvedItemDots } from '../../../../../../common/components/SolvedItemDots';
import CustomTable from '../../../../../../common/components/custom-table/CustomTable';
import {
	UserLatestSessions,
	useGetUserLatestSessions,
} from '../../../../hooks/useGetUserLatestSessions';

interface Props {
	userId: string;
}
const LoadingState = () => {
	return <Skeleton role="placeholder" borderRadius={'24px'} h="100px" />;
};

export const RecentActivity: React.FC<Props> = ({ userId }: Props) => {
	const { t } = useTranslation('dashboard');
	const navigate = useNavigate();
	const sessions = useGetUserLatestSessions(userId, 3);
	const columnHelper = createColumnHelper<UserLatestSessions>();
	if (sessions === undefined) return <LoadingState />;
	if (sessions === null) return null;

	const columns = [
		columnHelper.accessor('createdAt', {
			header: t('common:date').toString(),
			cell: ({ row }) => {
				if (!row.original.createdAt) return null;
				return (
					<Text fontWeight={500} fontSize="13px">
						{formatDate(row.original.createdAt, 'MMM d, yyyy')}
					</Text>
				);
			},
		}),
		columnHelper.accessor('storyReferenceId', {
			header: t('common:mission').toString(),
			cell: ({ row }) => {
				if (!row.original.storyTitle) return '-';

				return (
					<Text width={'240px'} isTruncated>
						{row.original.storyTitle}
					</Text>
				);
			},
		}),
		columnHelper.accessor('analysis.score', {
			header: t('common:score').toString(),
			cell: ({ row }) => (
				<Flex direction="row" justifyContent="end" width={'100%'}>
					<Text px="1" borderRadius={'full'}>
						{Math.round(row.original.analysis.score)}
					</Text>
				</Flex>
			),
		}),
		columnHelper.display({
			header: t('common:results').toString(),
			cell: ({ row }) => {
				const results = row.original.analysis.items.map((x) => x.solved);

				return <SolvedItemDots items={results} />;
			},
		}),
	];

	return (
		<Flex direction="column">
			<Text fontSize={'20px'} fontWeight={'500'} color={'#0B1215'}>
				{t('dashboard:detailed_user_view:recent_activity')}
			</Text>
			<CustomTable<UserLatestSessions>
				withWrapper
				data={sessions}
				columns={columns}
				paginationPosition="none"
				onRowClick={(row) => {
					navigate(
						`/dashboard/user/${row.userId}/session/${row.id}?activeCallTabIndex=2`
					);
				}}
			/>
		</Flex>
	);
};
