import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useGetCompletedAssignedContentAllocationQuery } from '../../../../../redux/api/contentAllocationApi';
import { Flex, Text } from '@chakra-ui/react';
import { TrackAssignment } from './components/TrackAssignment';
import { PathsApiV1AssignmentsGetParametersQueryReferenceType } from '@jam/api-sdk/api';
import { StoryStatus } from '../../../../../common/hooks/useStoryStatus';
import { ReactElement } from 'react';

const LoadingState = () => {
	return <div role="placeholder">Loading...</div>;
};

export interface CompletedAssignmentsProps {
	userId?: string;
	renderStoryCardButton: (
		storyId: string,
		status?: StoryStatus
	) => ReactElement;
}

export const CompletedAssignments = ({
	userId,
	renderStoryCardButton,
}: CompletedAssignmentsProps) => {
	const { t } = useTranslation('common');

	const { data: contentAllocations } =
		useGetCompletedAssignedContentAllocationQuery(userId ?? skipToken);

	if (!contentAllocations) {
		return <LoadingState />;
	}

	return (
		<Flex direction="column" gap="8" mt="8">
			<Text fontSize="20px" fontWeight="500" textAlign="left">
				{t('your_mastered_tracks')}
			</Text>
			{contentAllocations.map((contentAllocation) => {
				return (
					contentAllocation.reference.type ===
						PathsApiV1AssignmentsGetParametersQueryReferenceType.journey && (
						<TrackAssignment
							key={contentAllocation.id}
							completedStoryIds={contentAllocation.completedStoryIds}
							journeyId={contentAllocation.reference.id}
							hideStatus={false}
							renderStoryButton={renderStoryCardButton}
						/>
					)
				);
			})}
		</Flex>
	);
};
