import { SchemaUserExperienceResponseDto } from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'leaderboard-api',
	baseQuery: buildBaseQuery('/api/v1/leaderboards'),
	endpoints: (builder) => ({
		getOverallLeaderboard: builder.query<
			SchemaUserExperienceResponseDto[],
			void
		>({
			query: () => 'overall',
		}),
		getWeeklyLeaderboard: builder.query<
			SchemaUserExperienceResponseDto[],
			void
		>({
			query: () => 'weekly',
		}),
	}),
});

export const { useGetOverallLeaderboardQuery, useGetWeeklyLeaderboardQuery } =
	api;

export default api;
