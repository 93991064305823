import { useAuthInfo } from '@propelauth/react';
import { loadSelectedOrg } from '../components/admin/SelectOrg';
import { useIsAdmin } from './useIsAdmin';

export type UserOrg = {
	orgName: string;
	orgId: string;
	userAssignedRole?: string;
	isRecruiting: boolean;
};

/**
 * checks if the user is a manager or owner in any of the organizations
 * @returns the orgId of the organization where the user is a manager or owner
 */
export const useUserOrg = (): UserOrg | undefined => {
	const isAdmin = useIsAdmin();
	const authInfo = useAuthInfo();
	const orgs = authInfo.orgHelper?.getOrgs();

	if (!orgs?.[0]) return;

	if (isAdmin) {
		const selectedOrg = loadSelectedOrg();

		if (selectedOrg) {
			return {
				orgName: selectedOrg.name,
				orgId: selectedOrg.id,
				userAssignedRole: 'Owner',
				isRecruiting: false,
			};
		} else {
			return;
		}
	}

	return {
		orgName: orgs[0].orgName,
		orgId: orgs[0].orgId,
		userAssignedRole: orgs[0].userAssignedRole,
		isRecruiting: orgs[0].orgMetadata?.isRecruiting === true,
	};
};
