import { ReactElement } from 'react';
import { StoryCard } from '../../../../../../common/components/story-detail/StoryCard';
import { useGetStoryDetails } from '../../../../../../common/hooks/useGetStoryDetails';

type StoryDetailsProps = {
	storyId: string;
	dueDate?: Date;
	userId?: string;
	renderStoryButton?: (storyId: string) => ReactElement;
};

export const StoryAssignment = ({
	storyId,
	dueDate,
	userId,
	renderStoryButton,
}: StoryDetailsProps) => {
	const story = useGetStoryDetails(storyId);

	if (!story) {
		return null;
	}

	return (
		<StoryCard
			story={story}
			dueDate={dueDate}
			userId={userId}
			renderStoryButton={renderStoryButton}
		/>
	);
};
