import { Box, Flex, IconButton, Stack, Text } from '@chakra-ui/react';
import { CaretLeft, IconProps } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { BreadCrumbItem } from '../../../../providers/breadcrumbs/NavigationBreadcrumbsProvider';
import React from 'react';

export type BreadCrumbProps = {
	items: BreadCrumbItem[];
	onBackClick?: () => void;
	showItems?: boolean;
	icon?: React.ForwardRefExoticComponent<
		IconProps & React.RefAttributes<SVGSVGElement>
	>;
};

export const BreadCrumbs = ({
	showItems = true,
	items,
	onBackClick,
	icon,
}: BreadCrumbProps) => {
	const IconComponent = icon ?? CaretLeft;
	return (
		<Box w="100%" mb="5" borderRadius={'md'} role="heading">
			<Flex
				direction={'row'}
				gap={10}
				justifyContent={'space-between'}
				alignItems={{ base: 'column', lg: 'center' }}
				w="100%"
			>
				<Flex alignItems={'center'} gap={3}>
					{onBackClick && (
						<IconButton
							width={'fit-content'}
							w={'fit-content'}
							px={0}
							size={'xs'}
							bg="gray.100"
							color={'#0B1215'}
							_hover={{ bg: 'gray.200' }}
							aria-label="Back"
							icon={<IconComponent size={20} />}
							onClick={onBackClick}
						/>
					)}
					{showItems && (
						<Stack
							direction="row"
							gap={3}
							divider={
								<Text color="#757575" fontSize={'14px'}>
									/
								</Text>
							}
						>
							{items?.map((item, index) => {
								return item.link ? (
									<Link to={item.link || '#'} key={index}>
										<Text
											color={items.length === index + 1 ? 'black' : '#757575'}
											fontSize={'14px'}
										>
											{item.title}
										</Text>
									</Link>
								) : (
									<Text
										key={index}
										color={items.length === index + 1 ? 'black' : '#757575'}
										fontSize={'14px'}
									>
										{item.title}
									</Text>
								);
							})}
						</Stack>
					)}
				</Flex>
			</Flex>
		</Box>
	);
};
