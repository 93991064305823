import {
	SchemaPendingInviteResponseDto,
	SchemaTeamResponseDto,
	SchemaUserResponseDto,
	WhoAmIResponseDTORole,
} from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'crm-api',
	tagTypes: ['organization-users', 'team-users', 'pending-invites', 'teams'],
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		getInvitedUsers: builder.query<SchemaPendingInviteResponseDto[], void>({
			providesTags: ['pending-invites'],
			query: () => `pending-invites`,
		}),
		getOrganizationUsers: builder.query<SchemaUserResponseDto[], void>({
			providesTags: ['organization-users'],
			query: () => 'users',
		}),
		getTeamUsers: builder.query<SchemaUserResponseDto[], string | undefined>({
			providesTags: ['team-users'],
			query: (teamId) => (teamId ? `users?teamId=${teamId}` : 'users'),
		}),
		getUser: builder.query<SchemaUserResponseDto, string>({
			providesTags: ['organization-users'],
			query: (id) => `users/${id}`,
		}),
		getOrgTeams: builder.query<SchemaTeamResponseDto[], void>({
			providesTags: ['teams'],
			query: () => `teams`,
		}),
		createTeam: builder.mutation<SchemaUserResponseDto, { name: string }>({
			invalidatesTags: ['teams'],
			query: (body) => ({
				url: 'teams',
				method: 'POST',
				body,
			}),
		}),
		addUserToTeam: builder.mutation<
			void,
			{
				userId: string;
				teamId: string;
			}
		>({
			invalidatesTags: ['team-users'],
			query: ({ userId, teamId }) => ({
				url: `teams/${teamId}/users/${userId}`,
				method: 'POST',
			}),
		}),
		removeUserFromTeam: builder.mutation<
			void,
			{
				userId: string;
				teamId: string;
			}
		>({
			invalidatesTags: ['team-users'],
			query: ({ userId, teamId }) => ({
				url: `teams/${teamId}/users/${userId}`,
				method: 'DELETE',
			}),
		}),
		getManageableUsers: builder.query<SchemaUserResponseDto[], void>({
			providesTags: ['organization-users'],
			query: () => 'users?manageable=true',
		}),
		completeOnboarding: builder.mutation<void, { feature: string }>({
			query: ({ feature }) => ({
				url: `profiles/complete-onboarding/${feature}`,
				method: 'PATCH',
			}),
		}),
		changeUserRole: builder.mutation<
			void,
			{ userId: string; role: WhoAmIResponseDTORole }
		>({
			invalidatesTags: ['organization-users'],
			query: ({ userId, role }) => ({
				url: `users/change-role`,
				method: 'PATCH',
				body: {
					userId,
					role,
				},
			}),
			async onQueryStarted({ userId, role }, { dispatch, queryFulfilled }) {
				const patchResult = dispatch(
					api.util.updateQueryData(
						'getOrganizationUsers',
						undefined,
						(draft) => {
							const user = draft.find((x) => x.id === userId);
							if (user) {
								user.role = role;
							}
						}
					)
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
		}),
		inviteUserToOrganization: builder.mutation<
			void,
			{ email: string; role: string }
		>({
			invalidatesTags: ['pending-invites'],
			query: ({ email, role }) => ({
				url: `pending-invites`,
				method: 'POST',
				body: {
					email,
					role,
				},
			}),
		}),
		changeLanguage: builder.mutation<
			void,
			{
				language: string;
			}
		>({
			query: (body) => ({
				url: 'profiles/change-language',
				method: 'PATCH',
				body,
			}),
		}),
		changeAccountInfo: builder.mutation<
			void,
			{
				firstName: string;
				lastName: string;
			}
		>({
			query: (body) => ({
				url: 'profiles/change-name',
				method: 'PATCH',
				body,
			}),
		}),
		changeAccountEmail: builder.mutation<
			void,
			{
				newEmail: string;
			}
		>({
			query: (body) => ({
				url: 'profiles/change-email',
				method: 'PATCH',
				body,
			}),
		}),
		changePassword: builder.mutation<
			void,
			{
				password: string;
			}
		>({
			query: (body) => ({
				url: 'profiles/change-password',
				method: 'PATCH',
				body,
			}),
		}),
	}),
});

export const {
	useGetInvitedUsersQuery,
	useChangeLanguageMutation,
	useChangePasswordMutation,
	useChangeAccountInfoMutation,
	useChangeAccountEmailMutation,
	useGetOrganizationUsersQuery,
	useGetTeamUsersQuery,
	useGetUserQuery,
	useGetOrgTeamsQuery,
	useCreateTeamMutation,
	useAddUserToTeamMutation,
	useRemoveUserFromTeamMutation,
	useInviteUserToOrganizationMutation,
	useChangeUserRoleMutation,
	useCompleteOnboardingMutation,
	useGetManageableUsersQuery,
} = api;
export default api;
