import { Flex } from '@chakra-ui/react';
import { SchemaJourneyResponseDto } from '@jam/api-sdk/api';
import { useTranslation } from 'react-i18next';
import { Chip } from 'front-library';

type JourneyLabelsProps = { journey: SchemaJourneyResponseDto };

export const JourneyLabels = ({ journey }: JourneyLabelsProps) => {
	const { t } = useTranslation('story');

	if (!journey) return null;

	return (
		<Flex flexWrap="wrap" gap="2">
			{journey.conversation_topic.map((topic) => (
				<Chip minH="28px" bg="white" key={topic} text={t(topic)} />
			))}
		</Flex>
	);
};
