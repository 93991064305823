import { useUserOrg } from './useUserOrg';

/**
 * checks if the user is a manager or owner in any of the organizations
 * @returns the orgId of the organization where the user is a manager or owner
 */
export const useIsRecruiting = (): boolean => {
	const org = useUserOrg();

	if (!org) return true;

	if (org.isRecruiting === true) {
		return true;
	}

	return false;
};
