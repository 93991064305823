import { Box, Button, keyframes, Text, Tooltip } from '@chakra-ui/react';
import { Play, SpeakerSimpleHigh } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/hooks';
import { selectBotIsSpeaking } from '../../../redux/slice';
import { CallStatus } from './CallStatus';

type CallerStatusConfig = {
	buttonText: string;
	fontColor: string;
	bgColor: string;
	buttonBorder?: string;
};

const statusConfigMap: Record<CallStatus, CallerStatusConfig> = {
	notStarted: {
		buttonText: 'start-call',
		bgColor: '#4241E4',
		fontColor: 'white',
	},
	loading: {
		buttonText: 'loading-call',
		bgColor: '#4241E4',
		fontColor: 'white',
	},
	started: {
		buttonText: 'end-call',
		bgColor: 'white',
		fontColor: 'black',
		buttonBorder: 'linear-gradient(180deg,  #7D4CD0, #F98CC4)',
	},
	finished: {
		buttonText: 'start-again',
		bgColor: '#4241E4',
		fontColor: 'white',
	},
};

type AnimatedCallButtonProps = {
	firstTime: boolean;
	isPrimary: boolean;
	callStatus: CallStatus;
	onCallerClick: () => void;
};

export const AnimatedCallButton = ({
	firstTime,
	isPrimary,
	callStatus,
	onCallerClick,
}: AnimatedCallButtonProps) => {
	const { t } = useTranslation(['call', 'story']);
	const botIsSpeaking = useAppSelector(selectBotIsSpeaking);

	const pulse = keyframes(
		botIsSpeaking
			? {
					'0%': { boxShadow: '0 0 0 2px rgba(204,169,44, 0.4)' },
					'20%': { boxShadow: '0 0 0 6px rgba(204,169,44, 0.2)' },
					'100%': { boxShadow: '0 0 0 2px rgba(204,169,44, 0.4)' },
				}
			: {}
	);

	const uiState = statusConfigMap[callStatus];

	if (callStatus === CallStatus.notStarted && !firstTime) {
		uiState.buttonText = 'start-again';
	}

	const fontColor = !isPrimary ? '#4241E4' : uiState.fontColor;
	const bgColor = !isPrimary ? 'white' : uiState.bgColor;
	const callTooltip =
		uiState.buttonText === 'start-again'
			? t('start-again-tooltip')
			: t('start-call-tooltip');

	return (
		<>
			{botIsSpeaking && <SpeakerSimpleHigh size={20} />}
			<Box
				borderRadius={'24px'}
				p="1px"
				bg={uiState.buttonBorder}
				animation={`${pulse} 0.4s infinite`}
			>
				<Tooltip
					p={3}
					lineHeight={'21px'}
					hasArrow
					maxW={'200px'}
					placement="top"
					bg={'#0B1215'}
					borderRadius={'24px'}
					fontSize={'12px'}
					border={'1px solid #E2E8F0'}
					isDisabled={callStatus === CallStatus.started}
					label={callTooltip}
				>
					<Button
						id="call-button"
						borderRadius={'16px'}
						textColor={fontColor}
						bg={bgColor}
						gap={'8px'}
						border={'1px'}
						_hover={{ bg: bgColor }}
						onClick={onCallerClick}
					>
						<Text>{t(uiState.buttonText)}</Text>
						<Play weight="bold" size={14} />
					</Button>
				</Tooltip>
			</Box>
		</>
	);
};
