import {
	Button,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { PathsApiV1AssignmentsGetParametersQueryReferenceType } from '@jam/api-sdk/api';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ZodType, z } from 'zod';
import {
	useGetJourneysQuery,
	useGetStoriesQuery,
} from '../../../redux/api/cmsApi';
import { useAssignContentMutation } from '../../../redux/api/contentAllocationApi';

type AssignContentModalProps = {
	userIds: string[];
	isOpen: boolean;
	onClose: () => void;
};

type ContentSelectionData = {
	contentId: string;
};

const ContentSelectionDataSchema: ZodType<ContentSelectionData> = z.object({
	contentId: z.string().min(1),
});

export const AssignContentModal = ({
	userIds,
	isOpen,
	onClose,
}: AssignContentModalProps) => {
	const { t } = useTranslation(['settings', 'common']);
	const toast = useToast();
	const { data: journeys } = useGetJourneysQuery();
	const { data: stories } = useGetStoriesQuery();
	const {
		register,
		handleSubmit,
		reset,
		formState: { isValid, isSubmitting },
	} = useForm<ContentSelectionData>({
		resolver: zodResolver(ContentSelectionDataSchema),
	});
	const [assignContent] = useAssignContentMutation();

	const onSubmit = async (data: ContentSelectionData) => {
		const [type, id] = data.contentId.split(':');
		await assignContent({
			userIds,
			reference: {
				type:
					type === 'journey'
						? PathsApiV1AssignmentsGetParametersQueryReferenceType.journey
						: PathsApiV1AssignmentsGetParametersQueryReferenceType.story,
				id,
			},
		});

		onClose();
		reset();
		toast({
			title: t('content-assignment-successfully'),
			status: 'success',
			duration: 3000,
			isClosable: true,
		});
	};

	if (!journeys || !stories) return null;

	return (
		<Modal
			size={'lg'}
			isOpen={isOpen}
			onClose={() => {
				reset();
				onClose();
			}}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize={'2xl'}>{t('assign-content')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Stack spacing={4}>
						<FormControl>
							<FormLabel>{t('select-content')}</FormLabel>
							<Select borderRadius={'full'} {...register('contentId')}>
								<optgroup label={t('common:tracks') ?? 'Tracks'}>
									{journeys.map((journey) => (
										<option key={journey.id} value={`journey:${journey.id}`}>
											{journey.title}
										</option>
									))}
								</optgroup>
								<optgroup label={t('common:missions') ?? 'Stories'}>
									{stories.map((story) => (
										<option key={story.id} value={`story:${story.id}`}>
											{story.title}
										</option>
									))}
								</optgroup>
							</Select>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						id="assign-content-button"
						borderRadius={'full'}
						type="submit"
						isLoading={isSubmitting}
						px="6"
						bgColor={isValid ? 'brand.500' : 'gray.500'}
						size="md"
						onClick={() => {
							if (!isValid) return;
							void handleSubmit(onSubmit)();
						}}
						mr={3}
					>
						{t('assign')}
					</Button>
					<Button
						borderRadius={'32px'}
						textColor={'black'}
						bg={'white'}
						gap={'16px'}
						border={'1px'}
						_hover={{ bg: 'white' }}
						onClick={() => {
							onClose();
							reset();
						}}
					>
						<Text>{t('cancel')}</Text>
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
