import { Skeleton } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useGetManageableUsersQuery } from '../../../redux/api/crmApi';
import { useActiveMission } from '../../dashboard/hooks/useActiveMission';
import { useShownUsers } from '../../dashboard/hooks/useShownUsers';
import { useGetStoriesQuery } from '../../../redux/api/cmsApi';
import { useGetUserPerformanceByRoundQuery } from '../../../redux/api/dashboardApi';
import { PerformanceChart } from '../../../common/components/PerformanceChart';

const LoadingSkeleton = () => {
	return <Skeleton role="placeholder" height="20px" />;
};

export const TeamPerformanceChart = () => {
	const { activeMission, setActiveMission } = useActiveMission();
	const { shownUsers, setShownUsers } = useShownUsers();

	const { data: missions } = useGetStoriesQuery();
	const { data: teamMembers } = useGetManageableUsersQuery();

	useEffect(() => {
		if (!shownUsers.length && teamMembers) {
			setShownUsers(teamMembers.slice(0, 10).map((user) => user.id));
		}
	}, [teamMembers]);

	const { data: apiPerformanceData } = useGetUserPerformanceByRoundQuery({
		users: shownUsers.filter((uid) => uid !== null),
		missionId: activeMission ?? '',
	});

	if (!apiPerformanceData || !missions) return <LoadingSkeleton />;

	const selectedMission = missions.find(
		(mission) => mission.id === activeMission
	);

	// Transform API performance data into chart-ready format
	// - Replace null scores with -1 to indicate no data
	// - Add entries for other selected users with -1 scores if they haven't played that round
	const data = apiPerformanceData.slice().map((x) => ({
		round: x[shownUsers[0] ? shownUsers[0] : '']?.round ?? 0,
		...Object.fromEntries(
			shownUsers
				.filter((uid) => uid !== null)
				.map((uid) => [
					uid,
					{
						...x[uid],
						score: x[uid]?.score ?? -1,
					},
				])
		),
	}));

	return (
		<PerformanceChart
			data={data}
			shownUsers={shownUsers.filter((uid) => uid !== null)}
			teamMembers={teamMembers ?? []}
			selectedMission={selectedMission}
			missions={missions ?? []}
			onMissionChange={setActiveMission}
			onUsersChange={setShownUsers}
		/>
	);
};
