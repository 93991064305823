import { useUserOrg } from './useUserOrg';

/**
 * Checks if the user has a specific role in their organization
 * @param role The role to check for ('Owner' or 'Manager')
 * @returns true if user has the specified role in their organization
 */
const useHasRole = (role: 'Owner' | 'Manager'): boolean => {
	const org = useUserOrg();

	if (!org) return false;

	return org.userAssignedRole === role;
};

/**
 * Checks if the user is an owner in their organization
 * @returns true if user is owner of their organization
 */
export const useIsOwner = (): boolean => useHasRole('Owner');

/**
 * Checks if the user is a manager in their organization
 * @returns true if user is manager of their organization
 */
export const useIsManager = (): boolean => useHasRole('Manager');
