import { Spinner } from '@chakra-ui/react';
import { WhoAmIResponseDTORole } from '@jam/api-sdk/api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useUserOrg } from '../hooks/useUserOrg';

type RoleCheckProps = {
	WrappedComponent: JSX.Element;
	requiredRoles: WhoAmIResponseDTORole[];
};

export const RoleCheck = ({
	WrappedComponent,
	requiredRoles,
}: RoleCheckProps): JSX.Element | null => {
	const org = useUserOrg();
	const userRole = z
		.nativeEnum(WhoAmIResponseDTORole)
		.optional()
		.parse(org?.userAssignedRole);
	const nav = useNavigate();

	useEffect(() => {
		if (userRole === undefined) {
			return;
		}
		if (!requiredRoles.includes(userRole)) {
			nav('/users/home');
		}
	}, [userRole]);

	if (!userRole || !requiredRoles.includes(userRole)) {
		return <Spinner role="status" size={'xl'} />;
	}
	return WrappedComponent;
};
