import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import { currentEnvNeedsSentry, getReactAppEnv } from './utils';

export function initSentry() {
	if (!currentEnvNeedsSentry()) {
		return;
	}

	Sentry.init({
		dsn: 'https://a4bf319560dd4cf3e9b63390b3f794db@o4506314947297280.ingest.sentry.io/4506314953523200',
		environment: getReactAppEnv(),
		integrations: [
			new Sentry.BrowserTracing({
				// See docs for support of different versions of variation of react router
				// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				),
			}),
			Sentry.captureConsoleIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		tracesSampleRate: 1.0,
		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});

	// we need the listeners if we want to catch exceptions not caught by the ErrorBoundary
	window.addEventListener('error', (e: ErrorEvent) => {
		//Prevents double throwing of errors, a React problem
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		if (e.error.hasBeenCaught !== undefined) {
			return false;
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		e.error.hasBeenCaught = true;
		Sentry.captureException(e);
	});

	window.addEventListener('unhandledrejection', (e: PromiseRejectionEvent) =>
		Sentry.captureException(e)
	);
}

export function createBrowserRouterFunction() {
	if (currentEnvNeedsSentry()) {
		return Sentry.wrapCreateBrowserRouter(createBrowserRouter);
	}

	return createBrowserRouter;
}
