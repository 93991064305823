import { z } from 'zod';

export enum VapiEventMessageType {
	ASSISTANT_REQUEST = 'assistant-request',
	FUNCTION_CALL = 'function-call',
	TOOL_CALLS = 'tool-calls',
	STATUS_UPDATE = 'status-update',
	END_OF_CALL_REPORT = 'end-of-call-report',
	HANG = 'hang',
	SPEECH_UPDATE = 'speech-update',
	TRANSCRIPT = 'transcript',
	CONVERSATION_UPDATE = 'conversation-update',
	USER_INTERRUPTED = 'user-interrupted',
}

export const ToolCallEndConversationFunctionNameSchema = z.enum([
	'szeneBeendet',
	'scenarioEnded',
]);

export type Metadata = {
	simulateSalesAgent?: boolean;
};

export type ConversationMessage = {
	role: string;
	content: string;
};

export type ClientEventMessage = {
	type: VapiEventMessageType;
	[key: string]: unknown;
};

export type ClientEventMessageToolCalls = {
	type: VapiEventMessageType.TOOL_CALLS;
	toolCalls: {
		function: {
			name: string;
		};
	}[];
};
