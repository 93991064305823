import { Select } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import { useGetOrganizationsQuery } from '../../../redux/api/adminApi';

const selectedOrgSchema = z.object({
	id: z.string(),
	name: z.string(),
	sagaIds: z.array(z.string()),
});

const SELECTED_ORG_KEY = 'selectedOrg';

export type ISelectOrg = z.infer<typeof selectedOrgSchema>;

export function loadSelectedOrg(): ISelectOrg | undefined {
	try {
		return selectedOrgSchema.parse(
			JSON.parse(localStorage.getItem(SELECTED_ORG_KEY) ?? '')
		);
	} catch {
		return undefined;
	}
}

export function saveSelectedOrg(org: ISelectOrg) {
	localStorage.setItem(SELECTED_ORG_KEY, JSON.stringify(org));
}

function removeSelectedOrg() {
	localStorage.removeItem(SELECTED_ORG_KEY);
}

export const SelectOrg = () => {
	const isMountRef = useRef(true);
	const [selectedOrg, setSelectedOrg] = useState<ISelectOrg | undefined>(
		loadSelectedOrg()
	);
	const { data } = useGetOrganizationsQuery();

	useEffect(() => {
		if (isMountRef.current) {
			isMountRef.current = false;
			return;
		}

		if (selectedOrg) {
			saveSelectedOrg(selectedOrg);
		} else {
			removeSelectedOrg();
		}

		window.location.assign('/');
	}, [selectedOrg]);

	const onChangeHandler = (id: string) => {
		const org = data?.find((o) => o.id === id);

		if (!org) {
			removeSelectedOrg();
		} else {
			setSelectedOrg(org);
		}
	};

	return (
		<Select
			role="select-tenant"
			value={selectedOrg?.id}
			placeholder={'Select org'}
			onChange={(e) => onChangeHandler(e.target.value)}
		>
			{data?.map((org) => (
				<option key={org.id} value={org.id}>
					{org.name}
				</option>
			))}
		</Select>
	);
};
