import {
	Box,
	Button,
	Flex,
	FormControl,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
} from '@chakra-ui/react';
import {
	CreateLearnerSessionFeedbackDTOLearnerSessionFeedbackPresets,
	SchemaCreateLearnerSessionFeedbackDto,
} from '@jam/api-sdk/api';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateSessionUserFeedbackMutation } from '../../../redux/api/userFeedbackApi';

type ReportAnIssueModalProps = {
	sessionId: string;
	isOpen: boolean;
	onClose: () => void;
};

export const ReportAnIssueModal = ({
	sessionId,
	isOpen,
	onClose,
}: ReportAnIssueModalProps) => {
	const { t } = useTranslation('call', { keyPrefix: 'report_an_issue_modal' });
	const [createLearnerSessionFeedback, { isLoading }] =
		useCreateSessionUserFeedbackMutation();
	const { register, watch, setValue, handleSubmit } =
		useForm<SchemaCreateLearnerSessionFeedbackDto>({
			defaultValues: {
				sessionId,
				content: '',
				learnerSessionFeedbackPresets: [],
			},
		});
	const learnerSessionFeedbackPresets = watch('learnerSessionFeedbackPresets');

	const onPresetClicked = (
		val: CreateLearnerSessionFeedbackDTOLearnerSessionFeedbackPresets
	) => {
		if (!learnerSessionFeedbackPresets.includes(val)) {
			setValue('learnerSessionFeedbackPresets', [
				...learnerSessionFeedbackPresets,
				val,
			]);
		} else {
			setValue(
				'learnerSessionFeedbackPresets',
				learnerSessionFeedbackPresets.filter((p) => p !== val)
			);
		}
	};

	const onSubmit = async (data: SchemaCreateLearnerSessionFeedbackDto) => {
		if (learnerSessionFeedbackPresets.length === 0) return;

		if (!sessionId) return;

		await createLearnerSessionFeedback(data);

		setValue('content', '');
		setValue('learnerSessionFeedbackPresets', []);

		onClose();
	};

	return (
		<Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize={'16px'}>{t('title')}</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Stack spacing={6}>
						<Flex flexWrap={'wrap'} gap="3">
							{Object.entries(
								CreateLearnerSessionFeedbackDTOLearnerSessionFeedbackPresets
							).map(([key, value]) => (
								<Box
									onClick={() => onPresetClicked(value)}
									key={key}
									cursor={'pointer'}
									color={
										learnerSessionFeedbackPresets.includes(value)
											? '#4241E4'
											: '#757575'
									}
									border={'1px'}
									borderColor={
										learnerSessionFeedbackPresets.includes(value)
											? '#4241E4'
											: '#D9D9D9'
									}
									px="2"
									bg={
										learnerSessionFeedbackPresets.includes(value)
											? 'rgba(66, 65, 228, 1, 0.08)'
											: 'transparent'
									}
									borderRadius={'24px'}
								>
									<Text fontSize={'12px'}>{t(`options.${value}`)}</Text>
								</Box>
							))}
						</Flex>

						<FormControl as="fieldset">
							<Input
								{...register('content')}
								placeholder={t('placeholder') ?? ''}
								size="sm"
							/>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						type="submit"
						isLoading={isLoading}
						borderRadius={'8px'}
						textColor={'white'}
						bg={
							learnerSessionFeedbackPresets.length > 0 ? '#4241E4' : '#D9D9D9'
						}
						_hover={{
							bg:
								learnerSessionFeedbackPresets.length > 0
									? '#4241E4'
									: '#D9D9D9',
						}}
						onClick={() => {
							void handleSubmit(onSubmit)();
						}}
					>
						{t('common:submit')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
