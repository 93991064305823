import { useAuthInfo } from '@propelauth/react';
import { useTranslation } from 'react-i18next';
import { PersonalSettingsTab } from '../features/settings/components/PersonalSettingsTab';
import { BasePage } from './common/BasePage';
import { useOriginPageBreadcrumbs } from '../common/hooks/useOriginPageBreadcrumbs';

export const SettingsPage = () => {
	const { t } = useTranslation('settings');
	const { user } = useAuthInfo();

	const steps = useOriginPageBreadcrumbs(
		'settings',
		'/settings',
		t('settings')
	);

	if (!user) return null;

	return (
		<BasePage
			subtitles={[t('account-description') ?? '']}
			title={`${user.firstName ?? ''} ${user.lastName ?? ''}`}
			breadcrumbsProps={{
				items: steps,
			}}
		>
			<PersonalSettingsTab />
		</BasePage>
	);
};
