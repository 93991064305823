import {
	Box,
	Button,
	Flex,
	SimpleGrid,
	Skeleton,
	SkeletonText,
	Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGetNextStories } from '../hooks/useGetNextStories';
import { StoryCard } from '../../../common/components/story-detail/StoryCard';
import { useAuthInfo } from '@propelauth/react';
import { StartOrResumeStoryButton } from '../../../common/components/story-detail/buttons/StartOrResumeStoryButton';

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="4" h={'400px'}>
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
				<Skeleton borderRadius={'8px'} h="350px" flex={1} />
			</Flex>
		</Flex>
	);
};

export const KeepLearning = () => {
	const { t } = useTranslation();
	const { upcomingStories, isLoading } = useGetNextStories();
	const { user } = useAuthInfo();

	if (isLoading) {
		return <LoadingState />;
	}

	const keepLearningStories = (upcomingStories ?? []).slice(1, 4);

	if (keepLearningStories.length === 0) {
		return <Flex h={'400px'}></Flex>;
	}

	return (
		<Box>
			<Flex mb="3" alignItems={'center'} justifyContent={'space-between'}>
				<Text w="fit-content" fontSize="20px" fontWeight="500">
					{t('keep_learning')}
				</Text>
				<Button
					as={Link}
					to="/progress?activeTab=1"
					bg={'gray.100'}
					size="sm"
					color={'#0b1215'}
				>
					{t('see_all')}
				</Button>
			</Flex>
			<SimpleGrid spacing={4} columns={[1, 1, 2, 2, 3]}>
				{keepLearningStories.map((story) => (
					<StoryCard
						key={story.id}
						userId={user?.userId}
						story={story}
						renderStoryButton={(storyId, status) => (
							<StartOrResumeStoryButton
								storyId={storyId}
								storyStatus={status}
							/>
						)}
					/>
				))}
			</SimpleGrid>
		</Box>
	);
};
