import { Button, Text, Tooltip } from '@chakra-ui/react';
import { Play } from '@phosphor-icons/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGoBack } from '../../../common/hooks/useGoBack';
import {
	StoryStatus,
	useStoryStatus,
} from '../../../common/hooks/useStoryStatus';
import { useNextStep } from '../hooks/useNextStep';

type NextStoryProps = {
	storyId: string;
	isPrimary: boolean;
};

export const NextStory = ({ storyId, isPrimary }: NextStoryProps) => {
	const nextStep = useNextStep();
	const goBack = useGoBack();
	const navigate = useNavigate();
	const { t } = useTranslation(['call', 'story', 'common']);
	const storyStatus = useStoryStatus(storyId);

	const onNextStoryClick = useCallback(() => {
		if (nextStep) {
			navigate(nextStep, { replace: true });
		} else {
			goBack();
		}
	}, [nextStep]);

	const nextButtonTooltip =
		storyStatus === StoryStatus.COMPLETED
			? t('next_mission-tooltip-completed')
			: t('next_mission-tooltip');

	const nextMissionLabel = nextStep ? t('next_mission') : t('common:back');

	return (
		<Tooltip
			p={3}
			lineHeight={'21px'}
			hasArrow
			isDisabled={!nextStep}
			maxW={'200px'}
			placement="top"
			bg={'#0B1215'}
			borderRadius={'24px'}
			fontSize={'12px'}
			border={'1px solid #E2E8F0'}
			label={nextButtonTooltip}
		>
			<Button
				id="next-story-button"
				mr="2"
				borderRadius={'16px'}
				textColor={isPrimary ? 'white' : '#4241E4'}
				borderColor={isPrimary ? '#4241E4' : 'white'}
				bg={isPrimary ? '#4241E4' : 'white'}
				gap={'8px'}
				border={'1px'}
				_hover={{
					bg: isPrimary ? '#4241E4' : 'white',
				}}
				onClick={() => void onNextStoryClick()}
			>
				<Text>{nextMissionLabel}</Text>
				{nextStep && <Play weight="bold" size={14} />}
			</Button>
		</Tooltip>
	);
};
