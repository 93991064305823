import { Skeleton, Stack } from '@chakra-ui/react';
import {
	SchemaSessionResponseDto,
	SessionResponseDTOAnalysisState,
} from '@jam/api-sdk/api';
import { X } from '@phosphor-icons/react';
import { TourProvider } from '@reactour/tour';
import React, { useEffect, useState } from 'react';
import { AppLayout } from '../common/components/AppLayout';
import { CallPageSteps } from '../common/components/onboarding/OnboardinSteps';
import { OnboardingNavigation } from '../common/components/onboarding/OnboardingNavigation';
import { OnboardingFeature } from '../common/types/Onboarding';
import { CallDetails } from '../features/call/components/CallDetails';
import { CallEndedReasonModal } from '../features/call/components/CallEndedReasonModal';
import { CallHeader } from '../features/call/components/CallHeader';
import { Caller } from '../features/call/components/Caller';
import { useActiveSession } from '../features/call/hooks/useActiveSession';
import { useActiveStory } from '../features/home/hooks/useActiveStory';
import { useGetStoryQuery } from '../redux/api/cmsApi';
import {
	useGetSessionQuery,
	useGetStoryLastSessionQuery,
	useGetStorySessionsQuery,
	useLazyGetUserTotalExperienceQuery,
} from '../redux/api/sessionApi';
import { BasePage } from './common/BasePage';

const LoadingState = () => {
	return (
		<AppLayout hideSidebar fullWidth>
			<CallHeader />
			<Stack direction="column" h="100%" spacing={10} p={10}>
				<Skeleton borderRadius={'8px'} h="200px" flex={1} />
			</Stack>
		</AppLayout>
	);
};

const useFetchPageData = (
	activeStory?: string,
	activeSession?: string,
	polling = 0
) => {
	const { data: story, isLoading } = useGetStoryQuery(activeStory || '', {
		skip: !activeStory,
	});

	const { data: storySessions } = useGetStorySessionsQuery(activeStory || '', {
		skip: !activeStory,
	});

	const { data: lastSession } = useGetStoryLastSessionQuery(activeStory || '', {
		// skip the query if there is no active story or if there is an active session
		skip: !activeStory || !!activeSession,
		pollingInterval: polling,
	});
	const { data: currentSession } = useGetSessionQuery(activeSession || '', {
		skip: !activeSession,
	});

	return {
		story,
		isLoading,
		storySessions,
		lastSession,
		currentSession,
	};
};

const useSessionPolling = (
	setPolling: React.Dispatch<React.SetStateAction<number>>,
	session?: SchemaSessionResponseDto
) => {
	const [getUserTotalExperience] = useLazyGetUserTotalExperienceQuery();

	// This useEffect is used to set the polling interval for the last session query based on the status of the last session analysis object.
	useEffect(() => {
		if (!session) {
			return;
		}

		// session is stuck in pending for more than 30 minutes give up
		if (new Date(session.createdAt).getTime() + 1000 * 60 * 30 < Date.now()) {
			setPolling(0);
			return;
		}

		if (
			session.analysis === null ||
			session.analysis.state !== SessionResponseDTOAnalysisState.DONE
		) {
			setPolling(3000);
		} else {
			setPolling(0);
			void getUserTotalExperience();
		}
	}, [setPolling, session]);

	return {};
};

const usePageData = () => {
	const { activeStory } = useActiveStory();
	const { activeSession } = useActiveSession();
	const [polling, setPolling] = useState(0);
	const { story, isLoading, storySessions, lastSession, currentSession } =
		useFetchPageData(
			activeStory ?? undefined,
			activeSession ?? undefined,
			polling
		);
	const session = activeSession ? currentSession : lastSession;

	useSessionPolling(setPolling, session ?? undefined);

	return {
		story,
		isLoading,
		storySessions,
		session,
		dueDate: undefined,
	};
};

export const CallPage = () => {
	const { story, isLoading, storySessions, session, dueDate } = usePageData();

	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<TourProvider
			steps={CallPageSteps}
			components={{
				Navigation: () => (
					<OnboardingNavigation feature={OnboardingFeature.CALL_PAGE} />
				),
			}}
		>
			<CallEndedReasonModal session={session} />

			<BasePage
				breadcrumbsProps={{
					items: [],
					icon: X,
					showDefaultBack: true,
				}}
				title={''}
				hideSidebar
				fullWidth
			>
				<Caller
					numberOfRounds={storySessions?.length || 0}
					story={story}
					dueDate={dueDate}
				/>
				<CallDetails story={story} session={session} />
			</BasePage>
		</TourProvider>
	);
};
