import { StringParam, useQueryParam, withDefault } from 'use-query-params';

export const useActiveMission = () => {
	const [activeMission, setActiveMission] = useQueryParam<string | null>(
		'activeMission',
		withDefault(StringParam, null)
	);

	return { activeMission, setActiveMission };
};
