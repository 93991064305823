import {
	Box,
	Button,
	Flex,
	Skeleton,
	SkeletonText,
	Text,
} from '@chakra-ui/react';
import { CaretRight } from '@phosphor-icons/react';
import { useAuthInfo } from '@propelauth/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { CallBar } from '../../../common/components/CallBar';
import {
	StoryStatus,
	useStoryStatus,
} from '../../../common/hooks/useStoryStatus';
import { useGetFirstNotCompletedStory } from '../../../common/utils/getfirstNotCompletedStory';

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="3">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<Flex gap="4" alignItems={'center'}>
				<Skeleton borderRadius={'8px'} h="175px" w="175px" />
				<SkeletonText w={'full'} noOfLines={5} spacing={'5'} />
			</Flex>
		</Flex>
	);
};

export const NextStory = () => {
	const { user } = useAuthInfo();
	const { t } = useTranslation(['home', 'story']);
	const navigate: NavigateFunction = useNavigate();
	const { activeStory, isLoading: isLoadingActiveStory } =
		useGetFirstNotCompletedStory();
	const status = useStoryStatus(activeStory?.id ?? '', user?.userId);

	const renderStoryButton = useCallback(
		(storyId: string) => {
			const buttonText =
				status === StoryStatus.IN_PROGRESS ? t('resume') : t('start');

			return (
				<Button
					id="next-story-start-button"
					borderRadius={'32px'}
					textColor={'white'}
					bg={'#4241E4'}
					gap={'4px'}
					_hover={{ bg: '#4241E4' }}
					onClick={() => navigate(`/call?storyId=${storyId}`)}
				>
					<Text>{buttonText}</Text>
					<CaretRight size="12px" />
				</Button>
			);
		},
		[status]
	);

	if (isLoadingActiveStory) {
		return <LoadingState />;
	}

	if (activeStory === null || !user) {
		return null;
	}

	return (
		<Box>
			<Flex mb="3" alignItems={'center'} gap="2">
				<Text w="fit-content" fontSize="20px" fontWeight="500">
					{t('your_next_mission')}
				</Text>
			</Flex>
			<CallBar
				story={activeStory}
				dueDate={activeStory.dueDate}
				userId={user.userId}
				renderStoryButton={renderStoryButton}
			/>
		</Box>
	);
};
