import { Button, Flex, Input } from '@chakra-ui/react';
import { SchemaTeamResponseDto } from '@jam/api-sdk/api';
import { Plus } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TeamsFilteringToolbar } from '../../manager/common/TeamsFilteringToolbar';

interface Props {
	search: string;
	setSearch: (value: string) => void;
	setSelectedTeamId: (value: string | undefined) => void;
	onAddTeam: () => void;
	teams: SchemaTeamResponseDto[];
}

export const RegisteredMembersTopBar: React.FC<Props> = ({
	search,
	setSearch,
	setSelectedTeamId,
	teams,
	onAddTeam,
}) => {
	const { t } = useTranslation(['common', 'team']);

	return (
		<Flex gap="5">
			<Input
				role="search"
				width={'280px'}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				placeholder={t('team:member_search_placeholder') ?? ''}
			/>
			<TeamsFilteringToolbar
				setSelectedTeamId={setSelectedTeamId}
				teams={teams}
				showTimeFilter={false}
			/>
			<Button
				id="add-team-button"
				leftIcon={<Plus size={15} />}
				type="submit"
				px="6"
				bgColor={'brand.500'}
				size="md"
				onClick={onAddTeam}
			>
				Add team
			</Button>
		</Flex>
	);
};
