import {
	SchemaCreateLearnerSessionFeedbackDto,
	SchemaCreateLearnerStoryItemFeedbackRequestDto,
	SchemaLearnerStoryFeedbackPaginatedResponseDto,
	SchemaLearnerStoryItemFeedbackPaginatedResponseDto,
} from '@jam/api-sdk/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { buildBaseQuery } from './buildBaseQuery';

const api = createApi({
	reducerPath: 'user-feedback-api',
	tagTypes: ['user-feedback', 'user-item-feedback'],
	baseQuery: buildBaseQuery('/api/v1'),
	endpoints: (builder) => ({
		getItemUserFeedbackBySessionId: builder.query<
			SchemaLearnerStoryItemFeedbackPaginatedResponseDto,
			string
		>({
			providesTags: ['user-item-feedback'],
			query: (sessionId: string) =>
				`story-item-feedbacks?sessionId=${sessionId}`,
		}),
		getStoryUserFeedback: builder.query<
			SchemaLearnerStoryFeedbackPaginatedResponseDto,
			string
		>({
			providesTags: ['user-feedback'],
			query: (sessionId: string) =>
				`story-feedbacks?storyReferenceId=${sessionId}`,
		}),
		createSessionUserFeedback: builder.mutation<
			void,
			SchemaCreateLearnerSessionFeedbackDto
		>({
			query: (dto: SchemaCreateLearnerSessionFeedbackDto) => ({
				url: 'session-feedbacks',
				method: 'POST',
				body: { ...dto },
			}),
		}),
		createItemUserFeedback: builder.mutation<
			SchemaCreateLearnerStoryItemFeedbackRequestDto,
			{
				content?: string;
				sessionId: string;
				storyItemReferenceId: string;
				positive: boolean;
				learnerStoryItemPresets?: string[];
			}
		>({
			invalidatesTags: ['user-item-feedback'],
			query: (req) => ({
				url: `story-item-feedbacks`,
				method: 'POST',
				body: req,
			}),
		}),
		createStoryUserFeedback: builder.mutation<
			{ id: string },
			{
				storyReferenceId: string;
				rating: number;
				content: string;
			}
		>({
			invalidatesTags: ['user-feedback'],
			query: (req) => ({
				url: `story-feedbacks`,
				method: 'POST',
				body: req,
			}),
		}),
	}),
});

export const {
	useGetItemUserFeedbackBySessionIdQuery,
	useGetStoryUserFeedbackQuery,
	useCreateItemUserFeedbackMutation,
	useCreateSessionUserFeedbackMutation,
	useCreateStoryUserFeedbackMutation,
} = api;
export default api;
