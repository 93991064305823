import { PathsApiV1SessionsGetParametersQueryType } from '@jam/api-sdk/api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserSessionActivityTable } from '../common/components/UserSessionActivityTable';
import { useOriginPageBreadcrumbs } from '../common/hooks/useOriginPageBreadcrumbs';
import { UploadTranscript } from '../features/real-call/UploadTranscript';
import { useGetRolePlayActivityQuery } from '../redux/api/dashboardApi';
import { BasePage } from './common/BasePage';

export const RealCallPage = () => {
	const { t } = useTranslation('home');

	const navigate = useNavigate();
	const { data, isLoading } = useGetRolePlayActivityQuery(
		{
			type: PathsApiV1SessionsGetParametersQueryType.REAL_CALL,
		},
		{
			refetchOnMountOrArgChange: true,
		}
	);
	const steps = useOriginPageBreadcrumbs(
		'real-call',
		'/real-call',
		t('real-calls')
	);

	return (
		<BasePage
			title={t('real-calls')}
			titleActions={
				<UploadTranscript
					onTranscriptUploaded={(session) => {
						navigate(
							`/real-call/user/${session.userId}/session/${session.id}?activeCallTabIndex=2`
						);
					}}
				/>
			}
			breadcrumbsProps={{
				items: steps,
			}}
		>
			<UserSessionActivityTable
				data={data}
				isLoading={isLoading}
				onRowClick={(row) => {
					navigate(
						`/real-call/user/${row.userId}/session/${row.sessionId}?activeCallTabIndex=2`
					);
				}}
			/>
		</BasePage>
	);
};
