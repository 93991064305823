import { Box, Flex, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import {
	PathsApiV1AssignmentsGetParametersQueryReferenceType,
	SchemaKeyStoryForImprovementDto,
} from '@jam/api-sdk/api';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useGetAssignedContentAllocationQuery } from '../../../../../../redux/api/contentAllocationApi';
import { useGetDetailedUserKeyMissionsForImprovementQuery } from '../../../../../../redux/api/dashboardApi';
import { KeyImprovementStoryCard } from './KeyImprovementStoryCard';

interface Props {
	userId: string;
}

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<SimpleGrid mt="6" columns={1} gap={10} h={'100%'}>
				<Skeleton borderRadius={'8px'} h="275px" flex={1} />
			</SimpleGrid>
		</Flex>
	);
};

export const KeyImprovementStories: React.FC<Props> = ({ userId }: Props) => {
	const { t } = useTranslation('dashboard');
	const { data, isLoading, error } =
		useGetDetailedUserKeyMissionsForImprovementQuery(userId ?? '', {
			skip: !userId,
		});
	const { data: assignedContent, isLoading: isLoadingAssignedContent } =
		useGetAssignedContentAllocationQuery(userId ?? skipToken);

	if (isLoading || isLoadingAssignedContent) {
		return <LoadingState />;
	}

	if (error) {
		return <p>Failed to load key missions. Please try again later.</p>;
	}

	const assignedStoryIds = (assignedContent ?? [])
		.filter(
			(assignment) =>
				assignment.reference.type ===
				PathsApiV1AssignmentsGetParametersQueryReferenceType.story
		)
		.map((assignment) => assignment.reference.id);

	return (
		<Flex direction="column" gap="6">
			{data && data.length > 0 ? (
				<>
					<Text fontSize={'20px'} fontWeight={'500'} color={'#0B1215'}>
						{t('dashboard:detailed_user_view:key_missions_for_improvement')}
					</Text>
					<SimpleGrid columns={3} gap={6}>
						{data?.map(
							(storyForImprovement: SchemaKeyStoryForImprovementDto) => (
								<KeyImprovementStoryCard
									key={`${storyForImprovement.storyReferenceId}-${storyForImprovement.averageScore}`}
									userId={userId}
									story={storyForImprovement}
									assigned={assignedStoryIds.includes(
										storyForImprovement.storyReferenceId
									)}
								/>
							)
						)}
					</SimpleGrid>
				</>
			) : (
				<Text fontSize={'20px'} fontWeight={'500'} color={'#0B1215'}>
					{t(
						'dashboard:detailed_user_view:no_key_missions_for_improvement_available'
					)}
				</Text>
			)}
		</Flex>
	);
};
