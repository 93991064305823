import { useEffect } from 'react';
import { useNavigationBreadcrumbs } from '../../providers/breadcrumbs/useNavigationBreadcrumbs';

export const useOriginPageBreadcrumbs = (
	id: string,
	link: string,
	title: string
) => {
	const { setOriginStep, steps } = useNavigationBreadcrumbs();

	useEffect(() => {
		if (!id || !title || !link) return;

		setOriginStep({
			id,
			link,
			title,
		});
	}, [id, title, link]);

	return steps;
};
