import React from 'react';
import { AppLayout } from '../../common/components/AppLayout';
import { BasePageNavigation } from './BasePageNavigation';
import { BasePageHeader } from './BasePageHeader';
import { Flex } from '@chakra-ui/react';
import { NavigationBreadCrumbsProps } from '../../common/components/app/navigation/NavigationBreadCrumbs';

export interface BasePageProps {
	title: string;
	subtitles?: string[];
	titleActions?: React.ReactNode;
	children?: React.ReactNode;
	breadcrumbsProps?: NavigationBreadCrumbsProps;
	hideSidebar?: boolean;
	fullWidth?: boolean;
}
export const BasePage: React.FC<BasePageProps> = ({
	title,
	subtitles,
	titleActions,
	children,
	breadcrumbsProps,
	hideSidebar,
	fullWidth,
}) => {
	return (
		<AppLayout hideSidebar={hideSidebar} fullWidth={fullWidth}>
			<BasePageNavigation
				breadcrumbsProps={breadcrumbsProps}
				fullWidthLayout={fullWidth}
			/>
			<BasePageHeader
				title={title}
				subtitles={subtitles}
				titleActions={titleActions}
				fullWidthLayout={fullWidth}
			/>
			<Flex direction="column" gap="8" my="8" px={fullWidth ? 10 : 'initial'}>
				{children}
			</Flex>
		</AppLayout>
	);
};
