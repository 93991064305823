import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AllJourneys } from '../features/gym/components/AllJourneys';
import { AllStories } from '../features/gym/components/AllStories';
import { BasePage } from './common/BasePage';
import { useOriginPageBreadcrumbs } from '../common/hooks/useOriginPageBreadcrumbs';

export const GymPage = () => {
	const { t } = useTranslation('gym');

	const steps = useOriginPageBreadcrumbs('gym-overview', '/gym', t('title'));

	return (
		<BasePage
			title={t('title')}
			breadcrumbsProps={{
				items: steps,
			}}
		>
			<Flex my="10" direction={{ base: 'column' }} gap="5">
				<AllStories />
				<AllJourneys />
			</Flex>
		</BasePage>
	);
};
