import { useLocation, useNavigate } from 'react-router-dom';

export const useGoBack = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const back = () => {
		if (location.key === 'default') {
			navigate('/');
		} else {
			navigate(-1);
		}
	};

	return back;
};
