import { Avatar, Flex, Text } from '@chakra-ui/react';
import { SchemaMemberPerformanceResponseDto } from '@jam/api-sdk/api';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../common/components/custom-table/CustomTable';
import { FilteringToolbar } from '../../../common/components/filtering/FilteringToolbar';
import { useGetTeamPerformanceByMemberQuery } from '../../../redux/api/dashboardApi';
import { useNavigate } from 'react-router-dom';

type TableData = SchemaMemberPerformanceResponseDto & {
	completionPercentage: number | null;
};

export const LoadingState = () => {
	return <div>Loading...</div>;
};

export const PerformanceByMember = () => {
	const { t } = useTranslation('performance');
	const navigate = useNavigate();
	const { data, isLoading } = useGetTeamPerformanceByMemberQuery();
	const columnHelper = createColumnHelper<TableData>();

	const columns = [
		columnHelper.accessor('userName', {
			header: t('performance_by_member.name').toString(),
			cell: (info) => {
				return (
					<Flex alignItems={'center'} gap="2">
						<Avatar size="sm" src={info.row.original.userPictureUrl ?? ''} />
						<Flex direction={'column'}>
							<Text>{info.getValue()}</Text>
						</Flex>
					</Flex>
				);
			},
		}),
		columnHelper.accessor('roundsPlayed', {
			header: t('performance_by_member.rounds_played').toString(),
			cell: (info) => info.getValue(),
		}),
		columnHelper.accessor('completionPercentage', {
			header: t('performance_by_member.missions_completed').toString(),
			cell: (info) =>
				`${info.row.original.missionsCompleted} / ${info.row.original.roundsPlayed}`,
		}),
		columnHelper.accessor('averageScore', {
			header: t('performance_by_member.average_score').toString(),
			cell: (info) => info.getValue() ?? '-',
		}),
		columnHelper.accessor('lastSessionDate', {
			header: t('performance_by_member.last_time_played').toString(),
			cell: (info) => {
				if (!info.row.original.lastSessionDate) return '-';
				return (
					<Text>
						{formatDate(info.row.original.lastSessionDate, 'MMM dd, yyyy')}
					</Text>
				);
			},
		}),
	];

	if (isLoading) {
		return <LoadingState />;
	}

	if (!data || data.length === 0) {
		return <div>No data</div>;
	}

	const tableData = data.map((performance) => ({
		...performance,
		completionPercentage:
			(performance.missionsCompleted / performance.roundsPlayed) * 100,
	}));

	return (
		<CustomTable<TableData, { userName: string }>
			renderFilterBar={(columnFilters, setColumnFilters) => (
				<FilteringToolbar
					filters={[
						{
							id: 'userName',
							type: 'text',
							placeholder: t('search_user_placeholder').toString(),
							onChange: (value) => setColumnFilters({ userName: value }),
							value: columnFilters.userName ?? '',
						},
					]}
				/>
			)}
			withWrapper
			defaultSorting={[
				{
					id: 'averageScore',
					desc: false,
				},
			]}
			data={tableData}
			columns={columns}
			onRowClick={(row) => {
				navigate(`/dashboard/user/${row.userId}`);
			}}
			paginationPosition="bottom"
		/>
	);
};
