import {
	Flex,
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import {
	SchemaSessionResponseDto,
	SchemaStoryResponseDto,
} from '@jam/api-sdk/api';
import { Lock } from '@phosphor-icons/react';
import { useTour } from '@reactour/tour';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsOnboarding } from '../../../common/hooks/useIsOnboarding';
import { useIsRecruiting } from '../../../common/hooks/useIsRecruiting';
import { OnboardingFeature } from '../../../common/types/Onboarding';
import { useActiveCallTabIndex } from '../hooks/useActiveCallTabIndex';
import { HintsAndGoals } from './HintsAndGoals';
import { SessionFeedback } from './SessionFeedback';
import { Transcript } from './Transcript';
import { MoreInfoTab } from './more-info/MoreInfoTab';

type CallDetailsProps = {
	story?: SchemaStoryResponseDto;
	session: SchemaSessionResponseDto | undefined | null;
};

export const CallDetails = ({ story, session }: CallDetailsProps) => {
	const { t } = useTranslation('call');
	const { activeCallTabIndex, setActiveCallTabIndex } = useActiveCallTabIndex();
	const { setIsOpen } = useTour();
	const isOnboarding = useIsOnboarding(OnboardingFeature.CALL_PAGE);
	const isRecruiting = useIsRecruiting();

	useEffect(() => {
		if (
			isOnboarding &&
			story &&
			session &&
			session.analysis &&
			session.analysis.items.length > 0
		) {
			setIsOpen(true);
		}
	}, [isOnboarding, setIsOpen, story, session]);

	return (
		<Flex gap="10">
			<Tabs w={'100%'} isLazy index={activeCallTabIndex ?? 0}>
				<TabList p="2" minW={'300px'}>
					<Tab
						fontWeight={'medium'}
						borderRadius={'24px'}
						_selected={{ color: '#4241E4' }}
						onClick={() => setActiveCallTabIndex(null, 'replaceIn')}
						fontSize={'16px'}
						isDisabled={!story}
					>
						{t('scenario')}
					</Tab>
					{!isRecruiting && (
						<Tab
							fontSize={'16px'}
							fontWeight={'medium'}
							borderRadius={'24px'}
							_selected={{ color: '#4241E4' }}
							onClick={() => setActiveCallTabIndex(1, 'replaceIn')}
							isDisabled={!story}
						>
							<Text>{t('hints')}</Text>
						</Tab>
					)}
					<Tab
						className="feedback-tab"
						fontSize={'16px'}
						fontWeight={'medium'}
						borderRadius={'24px'}
						_selected={{ color: '#4241E4' }}
						onClick={() =>
							setActiveCallTabIndex(isRecruiting ? 1 : 2, 'replaceIn')
						}
						isDisabled={!session}
					>
						{!session ? (
							<Tooltip
								bg={'#0B1215'}
								lineHeight={'21px'}
								hasArrow
								maxW={'200px'}
								placement="top"
								p={3}
								borderRadius={'24px'}
								fontSize={'12px'}
								border={'1px solid #E2E8F0'}
								label={t('complete_one_attempt_feedback')}
							>
								<Flex alignItems={'center'} gap="1">
									<Text color={'#757575'}>{t('feedback')}</Text>
									<Lock color={'#757575'} size={16} />
								</Flex>
							</Tooltip>
						) : (
							<Text>{t('feedback')}</Text>
						)}
					</Tab>
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<Flex w="100%">
					<TabPanels w="100%">
						<TabPanel pl={0} py="8" w="100%">
							<HintsAndGoals story={story} />
						</TabPanel>
						{!isRecruiting && (
							<TabPanel pl={0} w="100%">
								<MoreInfoTab story={story} />
							</TabPanel>
						)}
						<TabPanel pl={0} w="100%">
							<SessionFeedback session={session} />
						</TabPanel>
					</TabPanels>
					<Transcript session={session} persona={story?.persona} />
				</Flex>
			</Tabs>
		</Flex>
	);
};
