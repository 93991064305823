import { Image } from '@chakra-ui/react';
import { AppSelectProps } from 'front-library';
import { FilteringOptions } from '../../../common/types/FilteringTypes';

export const teamsFilter = {
	id: 'teamsFilter',
	type: 'select',
	placeholder: 'All teams',
	// Dynamically enriched by teams
	options: [],
	componentProps: {
		prefixIcon: <Image src="/icons/team.svg" />,
	} as AppSelectProps,
} as FilteringOptions;

export const dateFilter = {
	id: 'dateFilter',
	type: 'select',
	placeholder: 'All time',
} as FilteringOptions;
