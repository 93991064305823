import {
	Box,
	Button,
	Checkbox,
	Flex,
	SkeletonText,
	Stack,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { Plus } from '@phosphor-icons/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../common/components/custom-table/CustomTable';
import { FilteringToolbar } from '../../../common/components/filtering/FilteringToolbar';
import { useGetOrganizationUsersQuery } from '../../../redux/api/crmApi';
import { AssignContentModal } from './AssignContentModal';

const LoadingState = () => {
	return (
		<Flex role="placeholder" mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} spacing={'5'} />
		</Flex>
	);
};

type SelectableUser = {
	id: string;
	select: boolean;
	email: string;
};

export const UsersSelectionTable = () => {
	const columnHelper = createColumnHelper<SelectableUser>();

	const { t } = useTranslation(['settings', 'common']);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { data, isLoading } = useGetOrganizationUsersQuery();
	const [rowSelection, setRowSelection] = useState({});
	const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
	const [columns] = useState([
		columnHelper.accessor('select', {
			meta: { hideTitle: true, name: 'select' },
			size: 20,
			header: 'select',
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					disabled={!row.getCanSelect()}
					onChange={row.getToggleSelectedHandler()}
				/>
			),
		}),
		columnHelper.accessor('email', {
			header: t('common:email').toString(),
			cell: ({ row }) => (
				<Text fontWeight={'medium'}>{row.original.email}</Text>
			),
		}),
	]);
	const [userData, setUserData] = useState<SelectableUser[]>([]);

	useEffect(() => {
		setUserData(
			(data ?? [])
				.map((user) => ({
					id: user.id,
					email: user.email,
				}))
				.filter((user): user is { id: string; email: string } => !!user.email)
				.map((user) => ({
					id: user.id,
					select: false,
					email: user.email,
				}))
		);
	}, [data]);

	useEffect(() => {
		const ids = Object.keys(rowSelection)
			.map((key) => parseInt(key))
			.map((index) => userData[index]?.id)
			.filter((id) => !!id);

		setSelectedUserIds(ids);
	}, [rowSelection, userData]);

	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<Stack direction="column" spacing="5">
			<CustomTable<SelectableUser, { email: string }>
				rowSelection={rowSelection}
				data={userData}
				columns={columns}
				onRowSelectionChange={setRowSelection}
				renderFilterBar={(columnFilters, setColumnFilters) => (
					<Flex gap="5">
						<FilteringToolbar
							filters={[
								{
									id: 'email',
									type: 'text',
									placeholder: t('search-by-email') ?? '',
									onChange: (value) =>
										setColumnFilters({ ...columnFilters, email: value }),
									value: columnFilters.email ?? '',
								},
							]}
						/>
						<Button
							id="add-user-button"
							leftIcon={<Plus size={15} />}
							type="submit"
							px="6"
							bgColor={'brand.500'}
							size="md"
							isDisabled={Object.keys(rowSelection).length === 0}
							onClick={onOpen}
						>
							{t('assign-content')}
						</Button>
						<AssignContentModal
							userIds={selectedUserIds}
							isOpen={isOpen}
							onClose={onClose}
						/>
					</Flex>
				)}
			/>
		</Stack>
	);
};
