import { useEffect } from 'react';
import { BreadCrumbItem } from '../../providers/breadcrumbs/NavigationBreadcrumbsProvider';
import { useNavigationBreadcrumbs } from '../../providers/breadcrumbs/useNavigationBreadcrumbs';

export const usePageBreadcrumbs = (items: BreadCrumbItem[], skip = false) => {
	const { addStep, removeStep, steps } = useNavigationBreadcrumbs();

	useEffect(() => {
		if (!items?.length || skip) return;
		items.forEach((item) => {
			addStep(item);
		});
		return () => {
			items.forEach((item) => {
				removeStep(item.id);
			});
		};
	}, [JSON.stringify(items), skip]);

	return steps;
};
