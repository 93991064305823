import { Button } from '@chakra-ui/react';
import {
	PendingInviteResponseDTOStatus,
	WhoAmIResponseDTORole,
} from '@jam/api-sdk/api';
import { ArrowCounterClockwise } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const InviteSendReminderCell: React.FC<{
	row: {
		email: string;
		role?: WhoAmIResponseDTORole;
		status: PendingInviteResponseDTOStatus;
	};
	onClick: (email: string, role?: WhoAmIResponseDTORole) => void;
}> = ({ row, onClick }) => {
	const { t } = useTranslation();
	if (row.status !== PendingInviteResponseDTOStatus.expired) {
		return null;
	}
	return (
		<Button
			leftIcon={<ArrowCounterClockwise />}
			variant="plain"
			size="sm"
			onClick={() => {
				onClick(row.email, row.role);
			}}
		>
			{t('common:resend_invitation')}
		</Button>
	);
};
