import {
	add,
	identify,
	Identify,
	init,
	setUserId,
} from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { currentEnvNeedsAmplitude } from './utils';

const AMPLITUDE_API_KEY =
	(import.meta.env.VITE_AMPLITUDE_API_KEY as string) ?? '';

export const logUserInformation = (userInfo: Record<string, string>) => {
	const userData = new Identify();

	const keys = Object.keys(userInfo);
	keys.forEach((property) => {
		userData.set(property, userInfo[property]);
	});

	identify(userData);
};

export const logSetUserId = (userId: string) => {
	setUserId(userId);
};

export const initLogger = () => {
	if (!currentEnvNeedsAmplitude()) {
		return;
	}

	init(AMPLITUDE_API_KEY, { autocapture: true });

	const sessionReplay = sessionReplayPlugin({
		// should be lower for production, in heavy use
		sampleRate: 1,
	});

	add(sessionReplay);
};
