import { ReactElement } from 'react';
import { TrackAccordion } from '../../../../../../common/components/track-detail/TrackAccordion';
import { useGetJourneyQuery } from '../../../../../../redux/api/cmsApi';
import { StoryStatus } from '../../../../../../common/hooks/useStoryStatus';

type TrackAssignmentProps = {
	activeStoryId?: string;
	journeyId: string;
	completedStoryIds: string[];
	hideStatus?: boolean;
	userId?: string;
	renderStoryButton?: (storyId: string, status?: StoryStatus) => ReactElement;
};

export const TrackAssignment = ({
	journeyId,
	completedStoryIds,
	hideStatus,
	userId,
	renderStoryButton,
}: TrackAssignmentProps) => {
	const { data: journey } = useGetJourneyQuery(journeyId, {
		skip: !journeyId,
	});

	if (!journey) {
		return null;
	}

	return (
		<TrackAccordion
			hideStatus={hideStatus}
			userId={userId}
			journey={journey}
			completedStoryIds={completedStoryIds}
			renderStoryButton={renderStoryButton}
		/>
	);
};
