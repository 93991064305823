import { PathsApiV1SessionsGetParametersQueryDateFilter } from '@jam/api-sdk/api';
import { useNavigate } from 'react-router-dom';
import { UserSessionActivityTable } from '../../../../../common/components/UserSessionActivityTable';
import { useGetRolePlayActivityQuery } from '../../../../../redux/api/dashboardApi';

export const RolePlayAcrossUsersTable = ({
	selectedDateFilter,
	teamId,
}: {
	teamId: string | undefined;
	selectedDateFilter:
		| PathsApiV1SessionsGetParametersQueryDateFilter
		| undefined;
}) => {
	const navigate = useNavigate();
	const { data, isLoading } = useGetRolePlayActivityQuery({
		teamId,
		dateFilter:
			selectedDateFilter ||
			PathsApiV1SessionsGetParametersQueryDateFilter.all_time,
	});

	const finishedSessions = data?.filter((x) => x.results.length > 0);

	return (
		<UserSessionActivityTable
			data={finishedSessions}
			isLoading={isLoading}
			onRowClick={(row) => {
				navigate(
					`/dashboard/user/${row.userId}/session/${row.sessionId}?activeCallTabIndex=2`
				);
			}}
		/>
	);
};
