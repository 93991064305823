import { Spinner } from '@chakra-ui/react';
import { useIsAdmin } from '../hooks/useIsAdmin';

type AdminCheckProps = {
	WrappedComponent: JSX.Element;
};

export const AdminCheck = ({
	WrappedComponent,
}: AdminCheckProps): JSX.Element | null => {
	const isAdmin = useIsAdmin();

	if (!isAdmin) {
		return <Spinner role="status" size={'xl'} />;
	}

	return WrappedComponent;
};
