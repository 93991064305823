import { Box, Flex, Text } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { skipToken } from '@reduxjs/toolkit/query';
import { useTranslation } from 'react-i18next';
import { useGetJourneysQuery } from '../../../redux/api/cmsApi';
import { useGetAssignedContentAllocationQuery } from '../../../redux/api/contentAllocationApi';
import { TrackAssignment } from '../../dashboard/components/user-details/progress/components/TrackAssignment';
import { StartStoryButton } from '../../../common/components/story-detail/buttons/StartStoryButton';

export const AllJourneys = () => {
	const { user } = useAuthInfo();
	const { t } = useTranslation('gym');
	const { data: contentAllocations } = useGetAssignedContentAllocationQuery(
		user?.userId ?? skipToken
	);
	const { data: journeys } = useGetJourneysQuery();

	if (!journeys || !contentAllocations) {
		return null;
	}

	return (
		<Box my="10">
			<Flex mb="6" alignItems={'center'} gap="2">
				<Text
					w="fit-content"
					fontSize="2xl"
					color={'#757575'}
					fontWeight="semibold"
				>
					{t('all_tracks')}
				</Text>
			</Flex>
			<Flex direction="column" gap="4">
				{journeys.map((journey) => {
					const journeyContentAllocation = contentAllocations.find(
						(ca) => ca.reference.id === journey.id
					);

					return (
						<TrackAssignment
							key={journey.id}
							journeyId={journey.id}
							completedStoryIds={
								journeyContentAllocation?.completedStoryIds ?? []
							}
							hideStatus={true}
							renderStoryButton={(storyId) => (
								<StartStoryButton storyId={storyId} />
							)}
						/>
					);
				})}
			</Flex>
		</Box>
	);
};
