import { Stack } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { PreparedFilteringOptions } from '../../types/FilteringTypes';
import { FilteringElement } from './FilteringElement';

export interface FilteringToolbarProps {
	filters: PreparedFilteringOptions[];
}

export const FilteringToolbar = ({ filters = [] }: FilteringToolbarProps) => {
	const [shownFiltersIds, setShownFiltersIds] = useState<string[]>([]);
	const initiallyLoaded = useRef<boolean>(false);

	useEffect(() => {
		if (initiallyLoaded.current || !filters?.length) {
			return;
		}

		setShownFiltersIds(
			(filters?.filter((filter) => !filter.isExtra) ?? []).map(
				(filter) => filter.id
			)
		);
		initiallyLoaded.current = true;
	}, [filters]);

	const toShowFilters = filters.filter((filter) =>
		shownFiltersIds.includes(filter.id)
	);

	return (
		<Stack direction="row" gap={1} className="w-full" alignItems="center">
			{toShowFilters.map((filter) => {
				return <FilteringElement filter={filter} key={filter.id} />;
			})}
		</Stack>
	);
};
