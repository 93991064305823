import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

export const useActiveTab = () => {
	const [activeTab, setActiveTab] = useQueryParam<number | null>(
		'activeTab',
		withDefault(NumberParam, null)
	);

	return { activeTab, setActiveTab };
};
