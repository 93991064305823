import {
	AssignedContentResponseDTOType,
	PathsApiV1AssignmentsGetParametersQueryReferenceType,
	SchemaAssignedContentResponseDto,
	SchemaJourneyResponseDto,
	SchemaStoryResponseDto,
} from '@jam/api-sdk/api';
import { useAuthInfo } from '@propelauth/react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
	useGetJourneysQuery,
	useGetStoriesQuery,
} from '../../../redux/api/cmsApi';
import { useGetAssignedContentAllocationQuery } from '../../../redux/api/contentAllocationApi';

type StoryWithContentAllocation = SchemaStoryResponseDto & {
	type: AssignedContentResponseDTOType;
	journey?: SchemaJourneyResponseDto;
	trackTitle?: string;
	dueDate?: Date;
	index: number;
};

export const useGetNextStories = (): {
	upcomingStories: StoryWithContentAllocation[] | null;
	isLoading: boolean;
} => {
	const { user } = useAuthInfo();
	const { data: contentAllocations } = useGetAssignedContentAllocationQuery(
		user?.userId ?? skipToken
	);
	const { data: journeys } = useGetJourneysQuery();
	const { data: stories } = useGetStoriesQuery();

	if (!contentAllocations || !journeys || !stories) {
		return { upcomingStories: null, isLoading: true };
	}

	const completedStories = contentAllocations.flatMap(
		(journey: SchemaAssignedContentResponseDto) => journey.completedStoryIds
	);

	const allAllocatedStories: StoryWithContentAllocation[] = [];

	let counter = 0;
	// for each content allocation
	for (const allocation of contentAllocations) {
		if (
			allocation.reference.type ===
			PathsApiV1AssignmentsGetParametersQueryReferenceType.journey
		) {
			const journey = journeys.find((j) => j.id === allocation.reference.id);

			if (journey) {
				for (const story of journey.stories) {
					allAllocatedStories.push({
						...story,
						trackTitle: journey.title,
						journey,
						type: allocation.type,
						dueDate: allocation.dueDate
							? new Date(allocation.dueDate)
							: undefined,
						index: counter++,
					});
				}
			}
		} else if (
			allocation.reference.type ===
			PathsApiV1AssignmentsGetParametersQueryReferenceType.story
		) {
			const story = stories.find((s) => s.id === allocation.reference.id);

			if (story) {
				allAllocatedStories.push({
					...story,
					type: allocation.type,
					dueDate: allocation.dueDate
						? new Date(allocation.dueDate)
						: undefined,
					index: counter++,
				});
			}
		}
	}

	const upcomingStories = allAllocatedStories.filter(
		(story) => !completedStories.includes(story.id)
	);

	return {
		upcomingStories,
		isLoading: false,
	};
};
