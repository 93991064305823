import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoryCardButton } from '../StoryCardButton';

export interface ViewStoryButtonProps {
	storyId: string;
	journeyId?: string;
}

export const ViewStoryButton = ({
	storyId,
	journeyId,
	...props
}: ViewStoryButtonProps) => {
	const { t } = useTranslation('common');
	const navigate = useNavigate();

	return (
		<StoryCardButton
			label={t('view')}
			onClick={() => {
				navigate(`/journey/${journeyId}/mission/${storyId}`);
			}}
			{...props}
		/>
	);
};
