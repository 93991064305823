import {
	Button,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Stack,
	Text,
	Textarea,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ZodType, z } from 'zod';
import { useGetStoriesQuery } from '../../redux/api/cmsApi';
import { useGetManageableUsersQuery } from '../../redux/api/crmApi';

export type UploadTranscriptModalProps = {
	isOpen: boolean;
	onSubmit: (data: TranscriptData) => void | Promise<void>;
	onCancel: () => void;
};

export type TranscriptData = {
	transcript: string;
	userId: string;
	storyId: string;
};

const TranscriptDataSchema: ZodType<TranscriptData> = z.object({
	transcript: z.string().min(10),
	userId: z.string().uuid(),
	storyId: z.string(),
});

export const UploadTranscriptModal = ({
	isOpen,
	onCancel,
	onSubmit,
}: UploadTranscriptModalProps) => {
	const { t } = useTranslation('real-call');
	const { data: users } = useGetManageableUsersQuery();
	const { data: stories } = useGetStoriesQuery();
	const {
		register,
		handleSubmit,
		reset,
		formState: { isDirty, errors, isSubmitting, isValid },
	} = useForm<TranscriptData>({
		resolver: zodResolver(TranscriptDataSchema),
	});

	const onSubmitHandler = async (data: TranscriptData) => {
		await onSubmit(data);
		reset();
	};

	if (!users || !stories) return null;

	return (
		<Modal
			size={'lg'}
			isOpen={isOpen}
			onClose={() => {
				reset();
				onCancel();
			}}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize={'2xl'}>
					{t('upload-transcript-title')}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={6}>
					<Stack spacing={4}>
						<FormControl isInvalid={!!errors.transcript}>
							<FormLabel>{t('transcript')}</FormLabel>
							<Textarea
								borderRadius={'8px'}
								{...register('transcript')}
								noOfLines={10}
							/>
						</FormControl>
						<FormControl>
							<FormLabel>{t('select-user')}</FormLabel>
							<Select
								borderRadius={'8px'}
								{...register('userId')}
								placeholder={t('select-user') ?? 'Select user'}
							>
								{users.map((user) => (
									<option key={user.id} value={user.id}>
										{user.firstName} {user.lastName}
									</option>
								))}
							</Select>
						</FormControl>
						<FormControl>
							<FormLabel>{t('select-score-card')}</FormLabel>
							<Select
								borderRadius={'8px'}
								{...register('storyId')}
								placeholder={t('select-score-card') ?? 'Select score card'}
							>
								{stories.map((story) => (
									<option key={story.id} value={story.id}>
										{story.title}
									</option>
								))}
							</Select>
						</FormControl>
					</Stack>
				</ModalBody>
				<ModalFooter>
					<Button
						id="add-user-button"
						borderRadius={'full'}
						type="submit"
						isLoading={isSubmitting}
						isDisabled={!isValid}
						px="6"
						bgColor={isDirty ? 'brand.500' : 'gray.500'}
						size="md"
						onClick={() => {
							if (!isDirty) return;
							void handleSubmit(onSubmitHandler)();
						}}
						mr={3}
					>
						{t('upload')}
					</Button>
					<Button
						borderRadius={'32px'}
						textColor={'black'}
						bg={'white'}
						gap={'16px'}
						border={'1px'}
						_hover={{ bg: 'white' }}
						onClick={() => {
							onCancel();
							reset();
						}}
					>
						<Text>{t('cancel')}</Text>
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
