import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useActiveStory } from '../../home/hooks/useActiveStory';
import { useGetNextStories } from '../../home/hooks/useGetNextStories';

export const useNextStep = (): string | undefined => {
	const { upcomingStories } = useGetNextStories();
	const { activeStory } = useActiveStory();
	const [stepIdx] = useQueryParam<number>(
		'stepIdx',
		withDefault(NumberParam, 0)
	);

	if (!upcomingStories) {
		return undefined;
	}

	const lookupStories = upcomingStories.slice(stepIdx);

	const lookupStoryIndex = lookupStories.findIndex(
		(story) => story.id === activeStory
	);

	if (lookupStoryIndex === -1) {
		return undefined;
	}

	const nextStory = lookupStories[lookupStoryIndex + 1];

	if (!nextStory) {
		return undefined;
	}

	const newStepIdx = stepIdx + lookupStoryIndex + 1;

	return `/call?storyId=${nextStory.id}&stepIdx=${newStepIdx}`;
};
