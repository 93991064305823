import { Menu, MenuButton, Button, MenuList, MenuItem } from '@chakra-ui/react';
import { DotsThreeVertical } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
	missionId: string;
}

export const MissionDetailDots: React.FC<Props> = ({ missionId }: Props) => {
	const { t } = useTranslation('performance');
	const navigate = useNavigate();

	const handleSeeMission = () => {
		navigate(`/call?storyId=${missionId}`);
	};

	const handleTestMission = () => {
		navigate(`/call?storyId=${missionId}`);
	};

	return (
		<Menu>
			<MenuButton as={Button} variant="outlined">
				<DotsThreeVertical />
			</MenuButton>
			<MenuList>
				<MenuItem onClick={handleSeeMission}>
					{t('performance_by_mission.see_mission')}
				</MenuItem>
				<MenuItem onClick={handleTestMission}>
					{t('performance_by_mission.test_mission')}
				</MenuItem>
			</MenuList>
		</Menu>
	);
};
