import { Avatar, Box, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { SchemaSessionsTeamActivityReposeDto } from '@jam/api-sdk/api';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'date-fns';
import { t } from 'i18next';
import { SolvedItemDots } from './SolvedItemDots';
import CustomTable from './custom-table/CustomTable';
import { FilteringToolbar } from './filtering/FilteringToolbar';

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<Box display={'flex'} flexDirection={'column'} h="200px" w="100%" gap={2}>
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
		</Flex>
	);
};

export const UserSessionActivityTable = ({
	data,
	isLoading,
	onRowClick,
}: {
	data: SchemaSessionsTeamActivityReposeDto[] | null | undefined;
	isLoading: boolean;
	onRowClick?: (row: { userId: string; sessionId: string }) => void;
}) => {
	const columnHelper =
		createColumnHelper<SchemaSessionsTeamActivityReposeDto>();

	if (isLoading) return <LoadingState />;

	if (!data) return null;

	const columns = [
		columnHelper.accessor('createdAt', {
			header: t('common:date').toString(),
			cell: ({ row }) => {
				if (!row.original.createdAt) return null;
				return (
					<Box gap="4px" alignItems="start" w={'fit-content'} maxW="280px">
						<Text fontWeight={500} fontSize="13px">
							{formatDate(row.original.createdAt, 'MMM d, yyyy')}
						</Text>
						<Text fontSize={'13px'} color={'#757575'}>
							{formatDate(row.original.createdAt, 'h:mm a').toLowerCase()}
						</Text>
					</Box>
				);
			},
		}),
		columnHelper.accessor('userName', {
			header: t('dashboard:team_member').toString(),
			cell: ({ row }) => (
				<Flex alignItems={'center'} gap="12px" w={'fit-content'} maxW={'280px'}>
					<Avatar
						bg="gray"
						size="sm"
						name={row.original.userName}
						src={row.original.userPictureUrl}
					/>

					<Stack direction="column" alignItems="start" gap={'4px'}>
						<Text>{row.original.userName}</Text>
						<Text fontSize={'13px'} color={'#757575'}>
							{row.original.teamName}
						</Text>
					</Stack>
				</Flex>
			),
		}),
		columnHelper.accessor('storyReferenceId', {
			header: t('common:mission').toString(),
			cell: ({ row }) => {
				return (
					<Text width={'fit-content'} maxWidth={'240px'} isTruncated>
						{row.original.storyTitle}
					</Text>
				);
			},
		}),
		columnHelper.accessor('score', {
			header: t('common:score').toString(),
			cell: ({ row }) => <Text>{Math.round(row.original.score)}</Text>,
		}),
		columnHelper.accessor('results', {
			header: t('common:results').toString(),
			cell: ({ row }) => {
				return <SolvedItemDots showMax={4} items={row.original.results} />;
			},
		}),
	];

	return (
		<Flex gap={6} direction={'column'}>
			<Stack mt="5" direction="column" spacing="5">
				<CustomTable<SchemaSessionsTeamActivityReposeDto, { userName: string }>
					renderFilterBar={(columnFilters, setColumnFilters) => (
						<FilteringToolbar
							filters={[
								{
									id: 'email',
									type: 'text',
									placeholder: t('common:search_by_name') ?? '',
									onChange: (value) =>
										setColumnFilters({ ...columnFilters, userName: value }),
									value: columnFilters.userName ?? '',
								},
							]}
						/>
					)}
					withWrapper
					data={data}
					columns={columns}
					defaultSorting={[{ id: 'createdAt', desc: true }]}
					onRowClick={onRowClick}
					paginationPosition="bottom"
				/>
			</Stack>
		</Flex>
	);
};
