import {
	PathsApiV1SessionsGetParametersQueryDateFilter,
	SchemaTeamResponseDto,
} from '@jam/api-sdk/api';
import { useEffect, useState } from 'react';
import { FilteringToolbar } from '../../../../../common/components/filtering/FilteringToolbar';
import { useFilters } from '../../../../../common/hooks/useFilters';
import { FilteringOptions } from '../../../../../common/types/FilteringTypes';
import { dateFilter, teamsFilter } from '../../../config/filtersConfig';

export interface TeamsFilteringToolbarProps {
	setSelectedTeamId: (teamId: string | undefined) => void;
	setSelectedDateFilter?: (
		dateFilter: PathsApiV1SessionsGetParametersQueryDateFilter | undefined
	) => void;
	teams: SchemaTeamResponseDto[];
	showTimeFilter?: boolean;
}
export const TeamsFilteringToolbar = ({
	setSelectedTeamId,
	setSelectedDateFilter,
	teams,
	showTimeFilter = false,
}: TeamsFilteringToolbarProps) => {
	const filtersConfig: FilteringOptions[] = [teamsFilter, dateFilter];
	const [config, setConfig] = useState<FilteringOptions[]>(filtersConfig);

	useEffect(() => {
		const newConfig = [];
		newConfig.push({ ...filtersConfig[0] });
		newConfig[0].options =
			teams?.map((team) => ({
				value: team.id,
				label: team.name,
			})) ?? [];

		if (showTimeFilter) {
			newConfig.push({
				...filtersConfig[1],
			});
			newConfig[1].options = [
				{
					value: 'current_week',
					label: 'Current week',
				},
				{
					value: 'current_month',
					label: 'Current month',
				},
			];
		}

		setConfig(newConfig);
	}, [teams, showTimeFilter]);

	const preparedFilters = useFilters({
		filters: config,
	});

	useEffect(() => {
		const teamFilter = preparedFilters.find(
			(filter) => filter.id === 'teamsFilter'
		);

		setSelectedTeamId(teamFilter?.value);

		const newDateFilter = preparedFilters.find(
			(filter) => filter.id === 'dateFilter'
		);
		setSelectedDateFilter?.(
			newDateFilter?.value as
				| PathsApiV1SessionsGetParametersQueryDateFilter
				| undefined
		);
	}, [preparedFilters]);

	return <FilteringToolbar filters={preparedFilters} />;
};
