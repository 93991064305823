import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoryCardButton } from '../StoryCardButton';
import { StoryStatus } from '../../../hooks/useStoryStatus';

export interface StartOrResumeStoryButtonProps {
	storyId: string;
	storyStatus?: StoryStatus;
}

const mapStatusToLabel = (status: StoryStatus): string => {
	switch (status) {
		case StoryStatus.IN_PROGRESS:
			return 'resume_mission';
		default:
			return 'start_mission';
	}
};

export const StartOrResumeStoryButton = ({
	storyId,
	storyStatus,
}: StartOrResumeStoryButtonProps) => {
	const { t } = useTranslation('story');
	const navigate = useNavigate();

	const label = storyStatus ? mapStatusToLabel(storyStatus) : 'start_mission';

	return (
		<StoryCardButton
			label={t(label)}
			onClick={() => {
				navigate(`/call?storyId=${storyId}`);
			}}
		/>
	);
};
