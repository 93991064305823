import { Box, Flex, Skeleton, useDisclosure } from '@chakra-ui/react';
import { SchemaTeamResponseDto, SchemaUserOverviewDto } from '@jam/api-sdk/api';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CreatedAtCell } from '../../../../../common/components/custom-table/cells/common/CreatedAtCell';
import { UserAvatarCell } from '../../../../../common/components/custom-table/cells/user/UserAvatarCell';
import { UserRoleCell } from '../../../../../common/components/custom-table/cells/user/UserRoleCell';
import { UserTeamCell } from '../../../../../common/components/custom-table/cells/user/UserTeamCell';
import CustomTable from '../../../../../common/components/custom-table/CustomTable';
import { RegisteredMembersTopBar } from './RegisteredMembersTopBar';
import { TeamCreateModal } from './TeamCreateModal';
import { useRegisteredMembersTable } from './useRegisteredMembersTable';

const LoadingState = () => {
	return (
		<Flex role="placeholder" direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<Box display={'flex'} flexDirection={'column'} h="200px" w="100%" gap={2}>
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
		</Flex>
	);
};

interface Props {
	teams: SchemaTeamResponseDto[];
}

export const RegisteredMembers: React.FC<Props> = ({ teams }) => {
	const { t } = useTranslation(['common', 'team']);
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const {
		isLoading,
		data,
		setSelectedTeamId,
		onRoleChange,
		onTeamChange,
		onCreateTeam,
	} = useRegisteredMembersTable();

	const columnHelper = createColumnHelper<SchemaUserOverviewDto>();

	// Components defined in a separate file -> make sure this file stays small and readable
	const columns = [
		columnHelper.accessor('email', {
			header: t('common:user').toString(),
			cell: ({ row }) => <UserAvatarCell row={row.original} />,
		}),
		columnHelper.accessor('role', {
			header: t('common:role').toString(),
			cell: ({ row }) => (
				<UserRoleCell row={row.original} onRoleChange={onRoleChange} />
			),
		}),
		columnHelper.accessor('teams', {
			header: t('team:team').toString(),
			cell: ({ row }) => (
				<UserTeamCell
					teams={teams}
					row={row.original}
					onTeamChange={onTeamChange}
				/>
			),
		}),
		columnHelper.accessor('createdAt', {
			header: t('team:member_since').toString(),
			cell: ({ row }) => <CreatedAtCell row={row.original} />,
		}),
	];

	if (isLoading) return <LoadingState />;

	return (
		<Flex gap={6} direction={'column'}>
			<TeamCreateModal
				isOpen={isOpen}
				onClose={onClose}
				onSubmit={onCreateTeam}
			/>
			<CustomTable<SchemaUserOverviewDto, { email: string }>
				data={data ?? []}
				columns={columns as unknown as ColumnDef<SchemaUserOverviewDto, any>[]}
				onRowClick={(item) => {
					navigate(`/dashboard/user/${item.id}`);
				}}
				renderFilterBar={(columnFilters, setColumnFilters) => (
					<RegisteredMembersTopBar
						teams={teams}
						search={columnFilters.email ?? ''}
						setSearch={(email) => setColumnFilters({ ...columnFilters, email })}
						setSelectedTeamId={setSelectedTeamId}
						onAddTeam={onOpen}
					/>
				)}
				withWrapper
				paginationPosition="bottom"
			/>
		</Flex>
	);
};
