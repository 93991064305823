import { Select } from '@chakra-ui/react';
import { WhoAmIResponseDTORole } from '@jam/api-sdk/api';
import React from 'react';

export const UserRoleCell: React.FC<{
	row: {
		id?: string;
		role?: WhoAmIResponseDTORole;
	};
	onRoleChange?: (userId: string, role: WhoAmIResponseDTORole) => Promise<void>;
}> = ({ row, onRoleChange }) => {
	const supportsClick = !!onRoleChange;
	return (
		<Select
			borderRadius={'10px'}
			borderColor={'black'}
			size={'sm'}
			disabled={false}
			maxW="100px"
			value={row.role}
			cursor={supportsClick ? 'pointer' : 'not-allowed'}
			onChange={(e) => {
				if (!supportsClick || !row.id) return;
				void onRoleChange(row.id, e.target.value as WhoAmIResponseDTORole);
			}}
			onClick={(e) => {
				if (!supportsClick) return;
				// Prevent the row click event from firing
				e.stopPropagation();
			}}
		>
			<option value="Member">Member</option>
			<option value="Manager">Manager</option>
			<option value="Owner">Owner</option>
		</Select>
	);
};
