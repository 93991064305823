import { Flex, Stack, Text } from '@chakra-ui/react';
import React from 'react';

export interface BasePageHeaderProps {
	title: string;
	subtitles?: string[];
	titleActions?: React.ReactNode;
	fullWidthLayout?: boolean;
}
export const BasePageHeader: React.FC<BasePageHeaderProps> = ({
	title,
	subtitles,
	titleActions,
	fullWidthLayout = false,
}) => {
	return (
		<Flex
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			width="100%"
			px={fullWidthLayout ? 10 : 'initial'}
		>
			<Flex gap="4" direction="column" width="100%">
				<Text fontSize="3xl" fontWeight="medium">
					{title}
				</Text>
				{subtitles?.length && (
					<Stack
						direction="row"
						gap={2}
						width="full"
						divider={<Text color="gray.500">•</Text>}
					>
						{subtitles?.filter(Boolean)?.map((subtitle) => {
							return (
								<Text key={subtitle} fontSize="16px" color="gray.500">
									{subtitle}
								</Text>
							);
						})}
					</Stack>
				)}
			</Flex>
			{titleActions}
		</Flex>
	);
};
