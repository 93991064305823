import {
	Button,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const teamSchema = z.object({
	name: z.string().min(1),
});

type Team = z.infer<typeof teamSchema>;

export const TeamCreateModal = ({
	isOpen,
	onClose,
	onSubmit,
}: {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (data: Team) => void | Promise<void>;
}) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { isValid, isSubmitting },
	} = useForm<Team>({
		resolver: zodResolver(teamSchema),
	});

	const onCloseHandler = () => {
		reset();
		onClose();
	};

	return (
		<Modal size={'lg'} isOpen={isOpen} onClose={onCloseHandler}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader fontSize={'2xl'}>Create team</ModalHeader>
				<ModalCloseButton />
				<ModalBody pb={5}>
					<FormControl as="fieldset">
						<FormLabel>Name</FormLabel>
						<Input borderRadius={'full'} {...register('name')} />
					</FormControl>
				</ModalBody>
				<ModalFooter>
					<Button
						id="submit-org-data"
						type="submit"
						borderRadius={'full'}
						bgColor={isValid ? 'brand.500' : 'gray.500'}
						isLoading={isSubmitting}
						isDisabled={!isValid}
						size="md"
						onClick={() => {
							if (!isValid) return;

							void handleSubmit(onSubmit)();
							onCloseHandler();
						}}
						mr={3}
					>
						Submit
					</Button>
					<Button
						borderRadius={'32px'}
						textColor={'black'}
						bg={'white'}
						gap={'16px'}
						border={'1px'}
						_hover={{ bg: 'white' }}
						onClick={() => {
							onCloseHandler();
						}}
					>
						<Text>Cancel</Text>
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
